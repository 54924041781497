export const salesInvoices = [
    {
        _id: "7e794bb1-5206-47c9-9b3f-ed78c4e68bd5",
        invoiceNo: "INV-7E794BB1-SAL",
        client: "1e6f3091-6acd-4999-a0d8-b4ac29cefdbd",
        salesOrder: "3dab44f7-d755-41c7-b73b-c1c3ab3fe43b",
        // unpaid, paid, overdue
        status: "paid",
        items: [
            {
                name: "deposit for SO-3DAB44F7",
                quantity: "1",
                unitPrice: "46170.00",
            },
        ],
        currency: "HKD",
        serviceFee: "0.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "0.00",
        totalAmount: "46170.00",
        dueAt: "2020-12-03T13:00:00.000+00:00",
        issuedAt: "2020-12-02T06:41:12.000+00:00",
        paidAt: "2020-12-02T08:30:12.000+00:00",
        createdAt: "2020-12-02T06:40:12.000+00:00",
        updatedAt: "2020-12-02T08:32:12.000+00:00",
        createdBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        issuedBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        notes: "",
    },
    {
        _id: "8ecb4dec-dc19-4a9d-84fc-e6845f907266",
        invoiceNo: "INV-8ECB4DEC-SAL",
        client: "1e6f3091-6acd-4999-a0d8-b4ac29cefdbd",
        salesOrder: "3dab44f7-d755-41c7-b73b-c1c3ab3fe43b",
        // unpaid, paid, overdue
        status: "unpaid",
        items: [
            {
                name: "remaining balance for SO-3DAB44F7",
                quantity: "1",
                unitPrice: "107730.00",
            },
        ],
        currency: "HKD",
        serviceFee: "0.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "0.00",
        totalAmount: "107730.00",
        dueAt: "2021-01-19T13:00:00.000+00:00",
        issuedAt: "2021-01-04T01:35:00.000+00:00",
        paidAt: "",
        createdAt: "2021-01-04T01:30:00.000+00:00",
        updatedAt: "2021-01-04T01:38:00.000+00:00",
        createdBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        updatedBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        issuedBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        notes: "",
    },
    {
        _id: "cfa75fbb-d484-4b2f-9fcb-0087b8c72242",
        invoiceNo: "INV-CFA75FBB-SAL",
        client: "2e214095-34df-4c53-bacb-57f159ceec6d",
        salesOrder: "125ed4d4-ae88-4d25-865d-edb99f17b6fc",
        // unpaid, paid, overdue
        status: "paid",
        items: [
            {
                name: "deposit for SO-125ED4D4",
                quantity: "1",
                unitPrice: "45000.00",
            },
        ],
        currency: "HKD",
        serviceFee: "0.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "0.00",
        totalAmount: "45000.00",
        dueAt: "2020-12-14T13:00:00.000+00:00",
        issuedAt: "2020-12-11T02:23:00.000+00:00",
        paidAt: "2020-12-14T05:32:00.000+00:00",
        createdAt: "2020-12-11T02:18:00.000+00:00",
        updatedAt: "2020-12-14T05:45:00.000+00:00",
        createdBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        issuedBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        notes: "",
    },
    {
        _id: "cca16343-1f16-4778-8609-a0da5c87b830",
        invoiceNo: "INV-CCA16343-SAL",
        client: "dca1a4e0-7828-4782-9b7c-d6030a775a68",
        salesOrder: "9cc82fd0-05c4-4d5a-a0b5-2271ec6cab57",
        // unpaid, paid, overdue
        status: "paid",
        items: [
            {
                name: "deposit for SO-9CC82FD0",
                quantity: "1",
                unitPrice: "72000.00",
            },
        ],
        currency: "HKD",
        serviceFee: "0.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "0.00",
        totalAmount: "72000.00",
        dueAt: "2020-12-21T13:00:00.000+00:00",
        issuedAt: "2020-12-18T06:04:26.000+00:00",
        paidAt: "2020-12-21T04:19:00.000+00:00",
        createdAt: "2020-12-18T06:04:23.000+00:00",
        updatedAt: "2020-12-21T04:45:00.000+00:00",
        createdBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        issuedBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        notes: "",
    },
    {
        _id: "df38045d-ecae-483f-94bd-ccf95601b5c9",
        invoiceNo: "INV-DF38045D-SAL",
        client: "9383fc7e-7684-443f-bde9-36476db86efe",
        salesOrder: "0c3eb67d-8797-4024-8601-a195ebe2dc5a",
        // unpaid, paid, overdue
        status: "paid",
        items: [
            {
                name: "deposit for SO-0C3EB67D",
                quantity: "1",
                unitPrice: "51000.00",
            },
        ],
        currency: "HKD",
        serviceFee: "0.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "0.00",
        totalAmount: "51000.00",
        dueAt: "2020-12-11T13:00:00.000+00:00",
        issuedAt: "2020-12-10T04:56:12.000+00:00",
        paidAt: "2020-12-11T03:07:12.000+00:00",
        createdAt: "2020-12-10T04:50:12.000+00:00",
        updatedAt: "2020-12-11T03:12:12.000+00:00",
        createdBy: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        issuedBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        notes: "",
    },
    {
        _id: "1bbe47b8-2803-4fa4-9fd9-06626227a147",
        invoiceNo: "INV-1BBE47B8-SAL",
        client: "9383fc7e-7684-443f-bde9-36476db86efe",
        salesOrder: "0c3eb67d-8797-4024-8601-a195ebe2dc5a",
        // unpaid, paid, overdue
        status: "unpaid",
        items: [
            {
                name: "remaining balance for SO-0C3EB67D",
                quantity: "1",
                unitPrice: "119000.00",
            },
        ],
        currency: "HKD",
        serviceFee: "0.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "0.00",
        totalAmount: "119000.00",
        dueAt: "",
        issuedAt: "",
        paidAt: "",
        createdAt: "2020-12-28T06:37:12.000+00:00",
        updatedAt: "2020-12-28T06:37:12.000+00:00",
        createdBy: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        updatedBy: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        issuedBy: "",
        notes: "",
    },
    {
        _id: "ca0db6c9-b9c0-42f0-ac82-10ae9e192216",
        invoiceNo: "INV-CA0DB6C9-SAL",
        client: "45b6a3c1-c8f9-423f-95d8-133574f5538b",
        salesOrder: "e4fd32e2-fa8f-472d-862a-b88a691c96d5",
        // unpaid, paid, overdue
        status: "paid",
        items: [
            {
                name: "deposit for SO-E4FD32E2",
                quantity: "1",
                unitPrice: "42750.00",
            },
        ],
        currency: "HKD",
        serviceFee: "0.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "0.00",
        totalAmount: "42750.00",
        dueAt: "2020-12-18T13:00:00.000+00:00",
        issuedAt: "2020-12-18T06:32:12.000+00:00",
        paidAt: "2020-12-18T08:25:12.000+00:00",
        createdAt: "2020-12-18T06:28:12.000+00:00",
        updatedAt: "2020-12-18T08:33:12.000+00:00",
        createdBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        issuedBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        notes: "",
    },
    {
        _id: "03b84f62-53fa-4b25-b9bb-8f17ea22c4f3",
        invoiceNo: "INV-03B84F62-SAL",
        client: "d0ed9856-1f26-4b34-be6a-4bf52f2603a6",
        salesOrder: "5cb834f0-76cb-4219-af74-e8c4c9ca1b66",
        // unpaid, paid, overdue
        status: "paid",
        items: [
            {
                name: "deposit for SO-5CB834F0",
                quantity: "1",
                unitPrice: "45900.00",
            },
        ],
        currency: "HKD",
        serviceFee: "0.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "0.00",
        totalAmount: "45900.00",
        dueAt: "2020-12-16T13:00:00.000+00:00",
        issuedAt: "2020-12-15T09:26:12.000+00:00",
        paidAt: "2020-12-16T02:34:12.000+00:00",
        createdAt: "2020-12-15T09:18:12.000+00:00",
        updatedAt: "2020-12-16T02:56:12.000+00:00",
        createdBy: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        issuedBy: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        notes: "",
    },
]