export const purchaseOrders = [
    {
        _id: "9a745e0d-81a7-4b87-ac74-3da9351c272b",
        poNo: "PO-9A745E0D",
        supplier: "6e85889f-cdf9-4a92-9da5-770dbab2861e",
        supplierQuote: "b6567dd7-d34f-4262-92b8-b1a4f60aafe6",
        items: [
            {
                name: "Medium promotion booth 10' x 6' daily rental",
                quantity: "14",
                unitPrice: "1500.00",
            },
            {
                name: "Promotion banners",
                quantity: "4",
                unitPrice: "350.00",
            },
        ],
        currency: "HKD",
        serviceFee: "500.00",
        shippingFee: "1500.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "900.00",
        totalAmount: "23500.00",
        paymentTerms: "30% upfront, NET 30",
        shippingAddress: "Shop 415, Level 4, Ocean Centre, Harbour City, Tsim Sha Tsui, Kowloon, Hong Kong",
        deliverAt: "2020-12-21T01:00:00.000+00:00",
        createdAt: "2020-12-04T03:15:12.868+00:00",
        updatedAt: "2020-12-04T03:15:12.868+00:00",
        issuedAt: "2020-12-04T03:48:12.000+00:00",
        createdBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        updatedBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        issuedBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        notes: "",
    },
    {
        _id: "51b30aeb-6153-4ff6-8c7f-98c7ab4e3640",
        poNo: "PO-51B30AEB",
        supplier: "40d7f4c8-e39f-4ab9-ae09-97c878e5d2d6",
        supplierQuote: "1612fbd3-5b80-4797-9e1d-58896f3653b2",
        items: [
            {
                name: "Commercial media advertisement",
                quantity: "1",
                unitPrice: "62500.00",
            },
        ],
        currency: "HKD",
        serviceFee: "1000.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "0.00",
        totalAmount: "63500.00",
        paymentTerms: "30% upfront, NET 30",
        shippingAddress: "upload to secure google cloud drive",
        deliverAt: "2020-12-09T04:00:00.000+00:00",
        createdAt: "2020-12-02T07:12:12.868+00:00",
        updatedAt: "2020-12-02T07:12:12.868+00:00",
        issuedAt: "2020-12-02T07:25:12.000+00:00",
        createdBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        updatedBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        issuedBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        notes: "",
    },
    {
        _id: "9400884f-3c6a-4701-abac-295fc7bb642c",
        poNo: "PO-9400884F",
        supplier: "6e85889f-cdf9-4a92-9da5-770dbab2861e",
        supplierQuote: "be6aa4b1-b95c-4cc8-9cb4-84161d83248e",
        items: [
            {
                name: "Small promotion booth 6' x 4'",
                quantity: "1",
                unitPrice: "7600.00",
            },
            {
                name: "Promotion banners",
                quantity: "2",
                unitPrice: "350.00",
            },
        ],
        currency: "HKD",
        serviceFee: "500.00",
        shippingFee: "1000.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "0.00",
        totalAmount: "9800.00",
        paymentTerms: "30% upfront, NET 30",
        shippingAddress: "IKEA, 38 Wang Chiu Rd, Kowloon Bay, Hong Kong",
        deliverAt: "2021-01-02T01:00:00.000+00:00",
        createdAt: "2020-12-21T06:18:12.868+00:00",
        updatedAt: "2020-12-21T06:18:12.868+00:00",
        issuedAt: "2020-12-22T02:32:12.000+00:00",
        createdBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        updatedBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        issuedBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        notes: "",
    },
    {
        _id: "e9702274-8359-496a-9739-29fd3861038b",
        poNo: "PO-E9702274",
        supplier: "99119e65-2be6-4b30-b15c-299d76bebb48",
        supplierQuote: "8eb041a6-d444-40a3-925e-8c5a682b8016",
        items: [
            {
                name: "Certified yoga instructors hourly rate",
                quantity: "24",
                unitPrice: "850.00",
            },
            {
                name: "Yoga training assistants hourly rate",
                quantity: "24",
                unitPrice: "350.00",
            },
        ],
        currency: "HKD",
        serviceFee: "500.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "300.00",
        totalAmount: "29000.00",
        paymentTerms: "50% upfront, NET 30",
        shippingAddress: "TBD, communicate with project manager one week in advance",
        deliverAt: "2021-01-16T05:00:00.000+00:00",
        createdAt: "2020-12-21T04:33:12.868+00:00",
        updatedAt: "2020-12-21T04:33:12.868+00:00",
        issuedAt: "2020-12-21T04:56:12.000+00:00",
        createdBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        updatedBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        issuedBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        notes: "",
    },
    {
        _id: "a6f49d1c-a80c-4e7b-9332-076ace428ec5",
        poNo: "PO-A6F49D1C",
        supplier: "224e190c-28ce-4f87-a79c-e977ef035f1f",
        supplierQuote: "7444d4ff-fd9d-45b6-82b7-41688e962356",
        items: [
            {
                name: "Event platform and staging daily rental",
                quantity: "1",
                unitPrice: "45000.00",
            },
            {
                name: "Event furniture chairs daily rental",
                quantity: "150",
                unitPrice: "50.00",
            },
            {
                name: "Event furniture bench daily rental",
                quantity: "4",
                unitPrice: "150.00",
            },
            {
                name: "Misc event items daily rental",
                quantity: "1",
                unitPrice: "1000.00",
            },
        ],
        currency: "HKD",
        serviceFee: "3500.00",
        shippingFee: "2000.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "1600.00",
        totalAmount: "58000.00",
        paymentTerms: "30% upfront with 10000 deposit, remaining due upon delivery. Deposit will be kept till rental product return, NET 30",
        shippingAddress: "Upper Ground Floor, China Hong Kong City, 33 Canton Road,, Tsim Sha Tsui, Hong Kong",
        deliverAt: "2020-12-23T01:00:00.000+00:00",
        createdAt: "2020-12-11T01:45:12.868+00:00",
        updatedAt: "2020-12-11T03:34:12.868+00:00",
        issuedAt: "2020-12-11T03:38:12.000+00:00",
        createdBy: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        updatedBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        issuedBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        notes: "",
    },
    {
        _id: "c4ce5550-5858-4be1-a259-7fee9775a55b",
        poNo: "PO-C4CE5550",
        supplier: "a769a2ed-cd4e-4166-82aa-687947618bb8",
        supplierQuote: "7371e44d-d65a-4b92-8327-5b32d6e02290",
        items: [
            {
                name: "Mall event security - standard type I, daily rate",
                quantity: "1",
                unitPrice: "25000.00",
            },
        ],
        currency: "HKD",
        serviceFee: "2500.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "1000.00",
        totalAmount: "26500.00",
        paymentTerms: "30% upfront, remaining due upon service completion, NET 30",
        shippingAddress: "Upper Ground Floor, China Hong Kong City, 33 Canton Road,, Tsim Sha Tsui, Hong Kong",
        deliverAt: "2020-12-23T01:00:00.000+00:00",
        createdAt: "2020-12-11T01:50:12.868+00:00",
        updatedAt: "2020-12-11T01:50:12.868+00:00",
        issuedAt: "2020-12-11T02:51:12.000+00:00",
        createdBy: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        updatedBy: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        issuedBy: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        notes: "",
    },
    {
        _id: "a5c9e7c6-55a2-4e39-8049-9b6ecc6a4656",
        poNo: "PO-A5C9E7C6",
        supplier: "11139cb7-2ffa-4342-8d8f-c008a9cb433f",
        supplierQuote: "a8212726-d011-4936-98f0-befc816866ff",
        items: [
            {
                name: "Photographer service daily",
                quantity: "1",
                unitPrice: "8500.00",
            },
        ],
        currency: "HKD",
        serviceFee: "500.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "0.00",
        totalAmount: "9000.00",
        paymentTerms: "50% upfront, NET 30",
        shippingAddress: "Upper Ground Floor, China Hong Kong City, 33 Canton Road,, Tsim Sha Tsui, Hong Kong",
        deliverAt: "2020-12-23T01:00:00.000+00:00",
        createdAt: "2020-12-11T02:12:12.868+00:00",
        updatedAt: "2020-12-11T02:12:12.868+00:00",
        issuedAt: "2020-12-11T02:52:12.000+00:00",
        createdBy: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        updatedBy: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        issuedBy: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        notes: "",
    },
    {
        _id: "c5d6e4c5-c3e6-405e-993e-aa57dbab72c9",
        poNo: "PO-C5D6E4C5",
        supplier: "795990b8-5011-4e24-aec9-57759e363cde",
        supplierQuote: "0ea302ec-db29-4322-8c1e-01b6cd4d78d7",
        items: [
            {
                name: "Bouquet flower",
                quantity: "6",
                unitPrice: "900.00",
            },
        ],
        currency: "HKD",
        serviceFee: "500.00",
        shippingFee: "600.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "0.00",
        totalAmount: "6500.00",
        paymentTerms: "pay in full upfront, NET 30",
        shippingAddress: "Upper Ground Floor, China Hong Kong City, 33 Canton Road,, Tsim Sha Tsui, Hong Kong",
        deliverAt: "2020-12-23T01:00:00.000+00:00",
        createdAt: "2020-12-11T02:23:12.868+00:00",
        updatedAt: "2020-12-11T02:23:12.868+00:00",
        issuedAt: "2020-12-11T02:56:12.000+00:00",
        createdBy: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        updatedBy: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        issuedBy: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        notes: "",
    },
    {
        _id: "0fb80a7a-b07c-41fd-bd3f-27256dcb06b6",
        poNo: "PO-0FB80A7A",
        supplier: "c754fba2-2c12-41a5-bce4-36f65a3aaeaa",
        supplierQuote: "5402d083-0b9e-429f-8f50-e6688e981296",
        items: [
            {
                name: "Packaging paper bags and boxes - various sizes",
                quantity: "150",
                unitPrice: "20.00",
            },
        ],
        currency: "HKD",
        serviceFee: "700.00",
        shippingFee: "300.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "0.00",
        totalAmount: "4000.00",
        paymentTerms: "pay in full upfront",
        shippingAddress: "Upper Ground Floor, China Hong Kong City, 33 Canton Road,, Tsim Sha Tsui, Hong Kong",
        deliverAt: "2020-12-23T01:00:00.000+00:00",
        createdAt: "2020-12-11T02:40:12.868+00:00",
        updatedAt: "2020-12-11T02:40:12.868+00:00",
        issuedAt: "2020-12-11T02:59:12.000+00:00",
        createdBy: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        updatedBy: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        issuedBy: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        notes: "",
    },
    {
        _id: "9acc6aba-a5dc-45de-94a8-25a1c3c0be77",
        poNo: "PO-9ACC6ABA",
        supplier: "198fa6f1-8204-46c3-a21c-039d2a3e4d5d",
        supplierQuote: "d9f1f048-bb67-4700-b2ab-b27d3834286d",
        items: [
            {
                name: "Commercial media advertisement",
                quantity: "1",
                unitPrice: "80000.00",
            },
        ],
        currency: "HKD",
        serviceFee: "1500.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "3000.00",
        totalAmount: "81500.00",
        paymentTerms: "30% upfront, NET 30",
        shippingAddress: "upload to secure google cloud drive",
        deliverAt: "2020-12-20T03:00:00.000+00:00",
        createdAt: "2020-12-18T06:56:12.868+00:00",
        updatedAt: "2020-12-18T06:56:12.868+00:00",
        issuedAt: "2020-12-18T07:16:12.000+00:00",
        createdBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        updatedBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        issuedBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        notes: "",
    },
    {
        _id: "56cde022-71c8-44e8-a7b6-503b241bd0b7",
        poNo: "PO-56CDE022",
        supplier: "40d7f4c8-e39f-4ab9-ae09-97c878e5d2d6",
        supplierQuote: "138a0688-00aa-433b-a7be-2b12266a90ce",
        items: [
            {
                name: "Commercial media advertisement",
                quantity: "1",
                unitPrice: "50000.00",
            },
        ],
        currency: "HKD",
        serviceFee: "1000.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "800.00",
        discount: "",
        totalAmount: "51800.00",
        paymentTerms: "30% upfront, NET 30",
        shippingAddress: "upload to secure google cloud drive",
        deliverAt: "2020-12-20T13:00:00.000+00:00",
        createdAt: "2020-12-15T09:46:12.868+00:00",
        updatedAt: "2020-12-15T09:46:12.868+00:00",
        issuedAt: "2020-12-15T09:52:12.000+00:00",
        createdBy: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        updatedBy: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        issuedBy: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        notes: "",
    },
    {
        _id: "5ccdc187-4b5d-46a2-a150-2fe5c7071293",
        poNo: "PO-5CCDC187",
        supplier: "fe674c7d-a786-4056-947d-d1ef75932caa",
        supplierQuote: "81a63446-5d53-4ab6-822c-4623fb38a8fc",
        items: [
            {
                name: "Dresses rental daily rate",
                quantity: "2",
                unitPrice: "2500.00",
            },
        ],
        currency: "HKD",
        serviceFee: "",
        shippingFee: "800.00",
        tax: "0.00",
        otherCharges: "200.00",
        discount: "",
        totalAmount: "3500.00",
        paymentTerms: "50% upfront, remaining due upon delivery",
        shippingAddress: "TBD, project manager will confirm by December 18, 2020",
        deliverAt: "2020-12-19T06:00:00.000+00:00",
        createdAt: "2020-12-15T11:15:12.868+00:00",
        updatedAt: "2020-12-16T02:04:12.868+00:00",
        issuedAt: "2020-12-16T02:31:12.000+00:00",
        createdBy: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        updatedBy: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        issuedBy: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        notes: "",
    },
]