import Vue from 'vue'
import Vuex from 'vuex'
import user from "./user"
import contents from "@/store/contents";

Vue.use(Vuex)

export const store = new Vuex.Store(
    {
      "modules": {
        user,
        contents
      },
    }
);

export default store;
