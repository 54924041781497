import localforage from "localforage";

const state = {
    staffs: [],
    clients: [],
    salesPartners: [],
    suppliers: [],
    quotationRequests: [],
    supplierQuotations: [],
    purchaseOrders: [],
    purchaseInvoices: [],
    salesQuotations: [],
    salesOrders: [],
    salesInvoices: [],
    campaigns: [],
    tasks: [],
    journalEntries: [],
    deliveryRecords: [],
}

const mutations = {
    fetchContents: (state, payload) => {
        state[payload.key] = payload.value;
    },

    updateContentMutate: (state, payload) => {
        const index = state[payload.key].findIndex(({ _id }) => _id === payload.value._id);
        if (index === -1) {
            state[payload.key].push(payload.value);
        } else {
            state[payload.key].splice(index, 1, payload.value);
        }
    },

    deleteContentMutate: (state, payload) => {
        const index = state[payload.key].findIndex(({ _id }) => _id === payload._id);
        if (index !== -1) {
            state[payload.key].splice(index, 1);
        }
    },

    updateContentAttributeMutate: (state, payload) => {
        const index = state[payload.key].findIndex(({ _id }) => _id === payload._id);
        if (index !== -1) {
            state[payload.key][index][payload.attribute] = payload.value;
        }
    },
}
const actions = {
    async updateContent ({ commit }, payload) {
        commit("updateContentMutate", payload);
        await localforage.setItem(payload.key, state[payload.key]);
    },

    async deleteContent ({ commit }, payload) {
        commit("deleteContentMutate", payload);
        await localforage.setItem(payload.key, state[payload.key]);
    },

    async updateContentAttribute ({ commit }, payload) {
        commit("updateContentAttributeMutate", payload);
        await localforage.setItem(payload.key, state[payload.key]);
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}