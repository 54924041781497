export const staffs = [
    {
        _id: "a0ec763e-5084-4e1f-af64-8249c3dde67b",
        firstName: "Yan Yi",
        lastName: "Chan",
        preferredName: "Jessica",
        staffNo: "STA-7ED7525C",
        gender: "F",
        avatar: "https://images.unsplash.com/photo-1565019011521-b0575cbb57c8?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=3900&q=80",
        address: "Flat 1806, Ocean Court Block 3, 3 Aberdeen Praya Road, Aberdeen, Hong Kong",
        DOB: "1986-06-12T00:00:00.000+00:00",
        phone: "93848829",
        email: "jessica.chan@cinque.com.hk",
        salary: "0.00",
        department: "Management",
        position: "Director",
        notes: ""
    },
    {
        _id: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        firstName: "Kin Hei",
        lastName: "Chan",
        preferredName: "Ray",
        staffNo: "STA-5A32F5F0",
        gender: "M",
        avatar: "https://images.unsplash.com/photo-1599229968215-92a142cc287a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2552&q=80",
        address: "Flat 2319, Hong Fung House, Cheung Hong Estate, 12 Ching Hong Rd, Tsing Yi, Hong Kong",
        DOB: "1983-12-16T00:00:00.000+00:00",
        phone: "26477465",
        email: "ray.chan@cinque.com.hk",
        salary: "19000.00",
        department: "Sales",
        position: "Sales Coordinator",
        notes: ""
    },
    {
        _id: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        firstName: "Hiu Ying",
        lastName: "Tam",
        preferredName: "Jennifer",
        staffNo: "STA-33AA85B7",
        gender: "F",
        avatar: "https://images.unsplash.com/photo-1575405369708-44948c7d9fcc?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2690&q=80",
        address: "Flat 2203, Whampoa Garden Site 10 Block 5, Hung Hom, Kowloon, Hong Kong",
        DOB: "1998-11-22T00:00:00.000+00:00",
        phone: "86948599",
        email: "jennifer.tam@cinque.com.hk",
        salary: "24000.00",
        department: "Sales",
        position: "Sales Manager",
        notes: ""
    },
    {
        _id: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        firstName: "Wai Yi",
        lastName: "Ho",
        preferredName: "Jenna",
        staffNo: "STA-B1A34485",
        gender: "F",
        avatar: "https://images.unsplash.com/photo-1524407806690-2b914a741648?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2536&q=80",
        address: "Flat 1506, Tsui Lok House, Tsui Ping (South) Estate, Tsui Ping Rd, Kwun Tong, Hong Kong",
        DOB: "1992-06-26T00:00:00.000+00:00",
        phone: "96379374",
        email: "jenna.ho@cinque.com.hk",
        salary: "21000.00",
        department: "Sales",
        position: "Sales Manager",
        notes: ""
    },
    {
        _id: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        firstName: "Siu Hong",
        lastName: "Cheung",
        preferredName: "Louis",
        staffNo: "STA-F6D10144",
        gender: "M",
        avatar: "https://images.unsplash.com/photo-1591607926780-b2fc1a8f390b?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2550&q=80",
        address: "Flat 906, Kam Din Terrace Hsia Kung Mansion, 24 Tai Koo Shing Rd, Taikoo Shing, Hong Kong",
        DOB: "1996-01-18T00:00:00.000+00:00",
        phone: "84591231",
        email: "louis.cheung@cinque.com.hk",
        salary: "16000.00",
        department: "Sales",
        position: "Sales Coordinator",
        notes: ""
    },
    {
        _id: "907368cd-ee6b-4743-815f-2a634605cf72",
        firstName: "Man Kit",
        lastName: "Wong",
        preferredName: "Jeremy",
        staffNo: "STA-907368CD",
        gender: "M",
        avatar: "https://images.unsplash.com/photo-1484557766192-1f82eda74f04?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2468&q=80",
        address: "Flat 2308, Shatinpark Stage 1 Ashley Garden, Sha Kok St, Sha Tin Wai, Hong Kong",
        DOB: "1989-01-16T00:00:00.000+00:00",
        phone: "28493847",
        email: "jeremy.wong@cinque.com.hk",
        salary: "16000.00",
        department: "Accounting",
        position: "BookKeeper",
        notes: ""
    },
    {
        _id: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        firstName: "Scarlett",
        lastName: "Gadoury",
        preferredName: "Scarlett",
        staffNo: "STA-DBDC9689",
        gender: "F",
        avatar: "https://images.unsplash.com/photo-1551866715-a41148565130?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2550&q=80",
        address: "Flat 2513, South Horizons Phase 4 BLK 30 Wai King Court, 30 Yi Nam Rd, South Horizons, Hong Kong",
        DOB: "1980-01-18T00:00:00.000+00:00",
        phone: "63749578",
        email: "scarlett.gadoury@cinque.com.hk",
        salary: "38000.00",
        department: "Project",
        position: "Lead Project Manager",
        notes: ""
    },
    {
        _id: "4ed130ed-858c-4c65-8d7f-b08cbb964f77",
        firstName: "Peter",
        lastName: "Smith",
        preferredName: "Peter",
        staffNo: "STA-4ED130ED",
        gender: "M",
        avatar: "https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2468&q=80",
        address: "Flat 2115, Park Island Tower 21, 8 Pak Lai Rd, Ma Wan, Hong Kong",
        DOB: "1993-08-02T00:00:00.000+00:00",
        phone: "93842948",
        email: "peter.smith@cinque.com.hk",
        salary: "28000.00",
        department: "Project",
        position: "Project Manager",
        notes: ""
    },
    {
        _id: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        firstName: "Ka Man",
        lastName: "Poon",
        preferredName: "Isabelle",
        staffNo: "STA-279FCEA9",
        gender: "F",
        avatar: "https://images.unsplash.com/photo-1585831818026-c75edc266a10?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
        address: "Flat 1519, City One Shatin Block 21, Tak Po St, Sha Tin, Hong Kong",
        DOB: "1990-12-10T00:00:00.000+00:00",
        phone: "93945834",
        email: "isabelle.poon@cinque.com.hk",
        salary: "16000.00",
        department: "Project",
        position: "Project Coordinator",
        notes: ""
    },
]