<template>
	<transition>
		<router-view></router-view>
	</transition>
</template>

<script>
export default {
	name: "RouteWrapper"
}
</script>

<style lang="scss" scoped>

</style>