import store from "../store";
import localforage from "localforage";
import { default as data } from "@/api";

export async function authenticate(to, from, next) {
    const passwordFound = await localforage.getItem("password");
    //await localforage.clear();
    if (passwordFound) {
        const items = Object.keys(data);
        await Promise.all(items.map(async (item) => {
            const itemFound = await localforage.getItem(item);
            if (itemFound) {
                if (item === "user") {
                    store.commit("user/updateUserMutate", data[item]);
                } else {
                    store.commit("contents/fetchContents", { key: item, value: itemFound });
                }
            } else {
                if (item === "user") {
                    store.commit("user/updateUserMutate", data[item]);
                } else {
                    store.commit("contents/fetchContents", { key: item, value: data[item] });
                }
                await localforage.setItem(item, data[item]);
            }
        }));
        next();
    } else {
        next("/login");
    }
}

export async function logout(to, from, next) {
    await localforage.removeItem("password");
    next();
}

export function verifyId(name, id, fallbackRoute, next) {
    const found = store.state.contents[name].find(({ _id }) => _id === id);
    if (found) {
        next();
    } else {
        next(fallbackRoute);
    }
}

