export const salesOrders = [
    {
        _id: "3dab44f7-d755-41c7-b73b-c1c3ab3fe43b",
        orderNo: "SO-3DAB44F7",
        client: "1e6f3091-6acd-4999-a0d8-b4ac29cefdbd",
        salesQuote: "ca714e08-bd77-4d8a-b545-09e18d262f13",
        items: [
            {
                name: "Daily front door booth promotion during Christmas and New Year holidays",
                quantity: "14",
                unitPrice: "15200.00",
            },
            {
                name: "TV advertisement package",
                quantity: "1",
                unitPrice: "95500.00",
            },
            {
                name: "Social media advertisement package",
                quantity: "1",
                unitPrice: "38200.00",
            },
        ],
        currency: "HKD",
        serviceFee: "25000.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "20000.00",
        totalAmount: "153900.00",
        paymentTerms: "30% upfront, remaining amount due upon campaign completion, NET 15",
        issuedAt: "2020-12-02T06:36:12.000+00:00",
        createdAt: "2020-12-02T06:35:12.868+00:00",
        updatedAt: "2020-12-02T06:38:12.868+00:00",
        issuedBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        createdBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        updatedBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        notes: "",
    },
    {
        _id: "125ed4d4-ae88-4d25-865d-edb99f17b6fc",
        orderNo: "SO-125ED4D4",
        client: "2e214095-34df-4c53-bacb-57f159ceec6d",
        salesQuote: "e8d98a1b-fa0e-4a35-a2f0-df2e343c5ae9",
        items: [
            {
                name: "Weekly smart home event hosting",
                quantity: "4",
                unitPrice: "32500",
            },
            {
                name: "Social media advertisement package",
                quantity: "1",
                unitPrice: "35000",
            }
        ],
        currency: "HKD",
        serviceFee: "13000.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "28000.00",
        totalAmount: "150000.00",
        paymentTerms: "30% upfront, remaining amount due upon campaign completion, NET 15",
        issuedAt: "2020-12-11T02:15:12.000+00:00",
        createdAt: "2020-12-10T05:48:12.868+00:00",
        updatedAt: "2020-12-11T02:19:12.868+00:00",
        issuedBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        createdBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        updatedBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        notes: "",
    },
    {
        _id: "9cc82fd0-05c4-4d5a-a0b5-2271ec6cab57",
        orderNo: "SO-9CC82FD0",
        client: "dca1a4e0-7828-4782-9b7c-d6030a775a68",
        salesQuote: "1d699e35-02e6-476f-aad9-bd0a6cc36e16",
        items: [
            {
                name: "Online streaming promotion for yoga compression apparel",
                quantity: "10",
                unitPrice: "3500",
            },
            {
                name: "Yoga training promotion section for Alphaskin in various districts",
                quantity: "6",
                unitPrice: "32000",
            },
            {
                name: "Social media advertisement package",
                quantity: "1",
                unitPrice: "28000",
            },
        ],
        currency: "HKD",
        serviceFee: "33000.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "48000.00",
        totalAmount: "240000.00",
        paymentTerms: "30% upfront, remaining amount due upon campaign completion, NET 15",
        issuedAt: "2020-12-18T06:04:19.000+00:00",
        createdAt: "2020-12-18T06:04:12.868+00:00",
        updatedAt: "2020-12-18T06:05:03.868+00:00",
        issuedBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        createdBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        updatedBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        notes: "",
    },
    {
        _id: "0c3eb67d-8797-4024-8601-a195ebe2dc5a",
        orderNo: "SO-0C3EB67D",
        client: "9383fc7e-7684-443f-bde9-36476db86efe",
        salesQuote: "7606ddc3-9099-425c-b83f-e34b9a7fb1bd",
        items: [
            {
                name: "Shopping mall promotion event",
                quantity: "1",
                unitPrice: "145000",
            },
            {
                name: "Social media advertisement package",
                quantity: "1",
                unitPrice: "30000",
            },
        ],
        currency: "HKD",
        serviceFee: "5000.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "10000.00",
        totalAmount: "170000.00",
        paymentTerms: "30% upfront, remaining amount due upon campaign completion, NET 15",
        issuedAt: "2020-12-10T04:48:12.000+00:00",
        createdAt: "2020-12-10T02:20:12.868+00:00",
        updatedAt: "2020-12-10T04:52:12.868+00:00",
        issuedBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        createdBy: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        updatedBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        notes: "",
    },
    {
        _id: "e4fd32e2-fa8f-472d-862a-b88a691c96d5",
        orderNo: "SO-E4FD32E2",
        client: "45b6a3c1-c8f9-423f-95d8-133574f5538b",
        salesQuote: "353a82cf-c74c-42e8-a9c6-fbb0a9e0f8dc",
        items: [
            {
                name: "TV advertisement package",
                quantity: "1",
                unitPrice: "85000",
            },
            {
                name: "Social media advertisement package",
                quantity: "1",
                unitPrice: "40000",
            },
            {
                name: "Email marketing",
                quantity: "1",
                unitPrice: "15000",
            },
        ],
        currency: "HKD",
        serviceFee: "15000.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "12500.00",
        totalAmount: "142500.00",
        paymentTerms: "30% upfront, remaining amount due upon campaign completion, NET 15",
        issuedAt: "2020-12-18T06:20:12.000+00:00",
        createdAt: "2020-12-18T06:16:12.868+00:00",
        updatedAt: "2020-12-18T06:25:12.868+00:00",
        issuedBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        createdBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        updatedBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        notes: "",
    },
    {
        _id: "5cb834f0-76cb-4219-af74-e8c4c9ca1b66",
        orderNo: "SO-5CB834F0",
        client: "d0ed9856-1f26-4b34-be6a-4bf52f2603a6",
        salesQuote: "7a0607b2-f330-4246-ae2f-8859c743977d",
        items: [
            {
                name: "TV advertisement package",
                quantity: "1",
                unitPrice: "70000",
            },
            {
                name: "Social media celebrity promotion",
                quantity: "1",
                unitPrice: "88000",
            },
        ],
        currency: "HKD",
        serviceFee: "10000.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "15000.00",
        totalAmount: "153000.00",
        paymentTerms: "30% upfront, remaining amount due upon campaign completion, NET 15",
        issuedAt: "2020-12-15T09:12:12.000+00:00",
        createdAt: "2020-12-15T09:08:12.868+00:00",
        updatedAt: "2020-12-15T09:18:12.868+00:00",
        issuedBy: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        createdBy: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        updatedBy: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        notes: "",
    },
]