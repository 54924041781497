export const supplierQuotations = [
    //ca714e08-bd77-4d8a-b545-09e18d262f13
    {
        _id: "b6567dd7-d34f-4262-92b8-b1a4f60aafe6",
        quoteNo: "QUO-B6567DD7-SUP",
        supplier: "6e85889f-cdf9-4a92-9da5-770dbab2861e",
        items: [
            {
                name: "Medium promotion booth 10' x 6' daily rental",
                quantity: "14",
                unitPrice: "1500.00",
            },
            {
                name: "Promotion banners",
                quantity: "4",
                unitPrice: "350.00",
            },
        ],
        deliverySchedule: "notice one week in advance, installation included in day of delivery",
        currency: "HKD",
        serviceFee: "500.00",
        shippingFee: "1500.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "900.00",
        totalAmount: "23500.00",
        paymentTerms: "30% upfront, NET 30",
        receivedAt: "2020-11-01T06:00:00.000+00:00",
        createdAt: "2020-11-01T06:28:12.868+00:00",
        updatedAt: "2020-11-01T06:28:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "Valid for 30 days",
    },
    //ca714e08-bd77-4d8a-b545-09e18d262f13
    {
        _id: "b18cae3f-de54-45d3-8311-b5684417b08c",
        quoteNo: "QUO-B18CAE3F-SUP",
        supplier: "198fa6f1-8204-46c3-a21c-039d2a3e4d5d",
        items: [
            {
                name: "Commercial media advertisement",
                quantity: "1",
                unitPrice: "65000.00",
            },
        ],
        deliverySchedule: "5 days standard production",
        currency: "HKD",
        serviceFee: "1500.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "1000.00",
        discount: "0.00",
        totalAmount: "67500.00",
        paymentTerms: "30% upfront, NET 30",
        receivedAt: "2020-10-30T03:30:00.000+00:00",
        createdAt: "2020-10-30T03:45:12.868+00:00",
        updatedAt: "2020-10-30T03:45:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "Valid for 60 days",
    },
    //ca714e08-bd77-4d8a-b545-09e18d262f13
    {
        _id: "1612fbd3-5b80-4797-9e1d-58896f3653b2",
        quoteNo: "QUO-1612FBD3-SUP",
        supplier: "40d7f4c8-e39f-4ab9-ae09-97c878e5d2d6",
        items: [
            {
                name: "Commercial media advertisement",
                quantity: "1",
                unitPrice: "62500.00",
            },
        ],
        deliverySchedule: "one week standard production",
        currency: "HKD",
        serviceFee: "1000.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "0.00",
        totalAmount: "63500.00",
        paymentTerms: "30% upfront, NET 30",
        receivedAt: "2020-11-02T02:00:00.000+00:00",
        createdAt: "2020-11-02T02:16:12.868+00:00",
        updatedAt: "2020-11-02T02:16:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "Valid for 60 days",
    },
    //e8d98a1b-fa0e-4a35-a2f0-df2e343c5ae9
    {
        _id: "be6aa4b1-b95c-4cc8-9cb4-84161d83248e",
        quoteNo: "QUO-BE6AA4B1-SUP",
        supplier: "6e85889f-cdf9-4a92-9da5-770dbab2861e",
        items: [
            {
                name: "Small promotion booth 6' x 4'",
                quantity: "1",
                unitPrice: "7600.00",
            },
            {
                name: "Promotion banners",
                quantity: "2",
                unitPrice: "350.00",
            },
        ],
        deliverySchedule: "notice 3 days in advance, installation included in day of delivery",
        currency: "HKD",
        serviceFee: "500.00",
        shippingFee: "1000.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "0.00",
        totalAmount: "9800.00",
        paymentTerms: "30% upfront, NET 30",
        receivedAt: "2020-12-01T06:12:00.000+00:00",
        createdAt: "2020-12-01T06:39:12.868+00:00",
        updatedAt: "2020-12-01T06:39:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "Valid for 30 days",
    },
    //1d699e35-02e6-476f-aad9-bd0a6cc36e16
    {
        _id: "8eb041a6-d444-40a3-925e-8c5a682b8016",
        quoteNo: "QUO-8EB041A6-SUP",
        supplier: "99119e65-2be6-4b30-b15c-299d76bebb48",
        items: [
            {
                name: "Certified yoga instructors hourly rate",
                quantity: "24",
                unitPrice: "850.00",
            },
            {
                name: "Yoga training assistants hourly rate",
                quantity: "24",
                unitPrice: "350.00",
            },
        ],
        deliverySchedule: "notice 2 days in advanced",
        currency: "HKD",
        serviceFee: "500.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "300.00",
        totalAmount: "29000.00",
        paymentTerms: "50% upfront, NET 30",
        receivedAt: "2020-11-25T08:46:00.000+00:00",
        createdAt: "2020-11-25T09:35:12.868+00:00",
        updatedAt: "2020-11-25T09:35:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "Valid for 30 days",
    },
    //7606ddc3-9099-425c-b83f-e34b9a7fb1bd
    {
        _id: "7444d4ff-fd9d-45b6-82b7-41688e962356",
        quoteNo: "QUO-7444D4FF-SUP",
        supplier: "224e190c-28ce-4f87-a79c-e977ef035f1f",
        items: [
            {
                name: "Event platform and staging daily rental",
                quantity: "1",
                unitPrice: "45000.00",
            },
            {
                name: "Event furniture chairs daily rental",
                quantity: "150",
                unitPrice: "50.00",
            },
            {
                name: "Event furniture bench daily rental",
                quantity: "4",
                unitPrice: "150.00",
            },
            {
                name: "Misc event items daily rental",
                quantity: "1",
                unitPrice: "1000.00",
            },
        ],
        deliverySchedule: "notice one week in advance, installation included in day of delivery",
        currency: "HKD",
        serviceFee: "3500.00",
        shippingFee: "2000.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "1600.00",
        totalAmount: "58000.00",
        paymentTerms: "30% upfront with 10000 deposit, remaining due upon delivery. Deposit will be kept till rental product return, NET 30",
        receivedAt: "2020-12-04T07:24:00.000+00:00",
        createdAt: "2020-12-04T07:33:12.868+00:00",
        updatedAt: "2020-12-04T07:33:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "Valid for 30 days",
    },
    //7606ddc3-9099-425c-b83f-e34b9a7fb1bd
    {
        _id: "7371e44d-d65a-4b92-8327-5b32d6e02290",
        quoteNo: "QUO-7371E44D-SUP",
        supplier: "a769a2ed-cd4e-4166-82aa-687947618bb8",
        items: [
            {
                name: "Mall event security - standard type I, daily rate",
                quantity: "1",
                unitPrice: "25000.00",
            },
        ],
        deliverySchedule: "notice one week in advance",
        currency: "HKD",
        serviceFee: "2500.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "1000.00",
        totalAmount: "26500.00",
        paymentTerms: "30% upfront, remaining due upon service completion, NET 30",
        receivedAt: "2020-12-02T02:38:00.000+00:00",
        createdAt: "2020-12-02T03:08:12.868+00:00",
        updatedAt: "2020-12-02T03:08:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "Valid for 30 days",
    },
    //7606ddc3-9099-425c-b83f-e34b9a7fb1bd
    {
        _id: "a8212726-d011-4936-98f0-befc816866ff",
        quoteNo: "QUO-A8212726-SUP",
        supplier: "11139cb7-2ffa-4342-8d8f-c008a9cb433f",
        items: [
            {
                name: "Photographer service daily",
                quantity: "1",
                unitPrice: "8500.00",
            },
        ],
        deliverySchedule: "notice 2 days in advanced",
        currency: "HKD",
        serviceFee: "500.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "0.00",
        totalAmount: "9000.00",
        paymentTerms: "50% upfront, NET 30",
        receivedAt: "2020-12-01T06:02:00.000+00:00",
        createdAt: "2020-12-01T06:19:12.868+00:00",
        updatedAt: "2020-12-01T06:19:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "Valid for 30 days",
    },
    //7606ddc3-9099-425c-b83f-e34b9a7fb1bd
    {
        _id: "0ea302ec-db29-4322-8c1e-01b6cd4d78d7",
        quoteNo: "QUO-0EA302EC-SUP",
        supplier: "795990b8-5011-4e24-aec9-57759e363cde",
        items: [
            {
                name: "Bouquet flower",
                quantity: "6",
                unitPrice: "900.00",
            },
        ],
        deliverySchedule: "one week in advance for uncommon flowers",
        currency: "HKD",
        serviceFee: "500.00",
        shippingFee: "600.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "0.00",
        totalAmount: "6000.00",
        paymentTerms: "pay in full upfront, NET 30",
        receivedAt: "2020-12-01T03:00:00.000+00:00",
        createdAt: "2020-12-01T03:34:12.868+00:00",
        updatedAt: "2020-12-01T03:34:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "Valid for 30 days",
    },
    //7606ddc3-9099-425c-b83f-e34b9a7fb1bd
    {
        _id: "5402d083-0b9e-429f-8f50-e6688e981296",
        quoteNo: "QUO-5402D083-SUP",
        supplier: "c754fba2-2c12-41a5-bce4-36f65a3aaeaa",
        items: [
            {
                name: "Packaging paper bags and boxes - various sizes",
                quantity: "150",
                unitPrice: "20.00",
            },
        ],
        deliverySchedule: "one day production",
        currency: "HKD",
        serviceFee: "700.00",
        shippingFee: "300.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "0.00",
        totalAmount: "4000.00",
        paymentTerms: "pay in full upfront, NET 30",
        receivedAt: "2020-12-03T04:48:00.000+00:00",
        createdAt: "2020-12-03T05:14:12.868+00:00",
        updatedAt: "2020-12-03T05:14:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "Valid for 30 days",
    },
    //353a82cf-c74c-42e8-a9c6-fbb0a9e0f8dc
    {
        _id: "67be3327-9225-4060-90b6-6d91e85ecafa",
        quoteNo: "QUO-67BE3327-SUP",
        supplier: "458d01af-c463-4daf-9843-44aec4617d3e",
        items: [
            {
                name: "Commercial media advertisement",
                quantity: "1",
                unitPrice: "68000.00",
            },
        ],
        deliverySchedule: "4 days urgent production, deliver within 5 days",
        currency: "HKD",
        serviceFee: "2500.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "1500.00",
        totalAmount: "71000.00",
        paymentTerms: "30% upfront, NET 30",
        receivedAt: "2020-12-10T02:38:00.000+00:00",
        createdAt: "2020-12-10T02:58:12.868+00:00",
        updatedAt: "2020-12-10T02:58:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "Valid for 30 days",
    },
    //353a82cf-c74c-42e8-a9c6-fbb0a9e0f8dc
    {
        _id: "d9f1f048-bb67-4700-b2ab-b27d3834286d",
        quoteNo: "QUO-D9F1F048-SUP",
        supplier: "198fa6f1-8204-46c3-a21c-039d2a3e4d5d",
        items: [
            {
                name: "Commercial media advertisement",
                quantity: "1",
                unitPrice: "80000.00",
            },
        ],
        deliverySchedule: "deliver within 3 days for urgent production",
        currency: "HKD",
        serviceFee: "1500.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "3000.00",
        totalAmount: "81500.00",
        paymentTerms: "30% upfront, NET 30",
        receivedAt: "2020-12-11T04:00:00.000+00:00",
        createdAt: "2020-12-11T04:16:12.868+00:00",
        updatedAt: "2020-12-11T04:16:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "Valid for 30 days",
    },
    //353a82cf-c74c-42e8-a9c6-fbb0a9e0f8dc
    {
        _id: "9e6b9375-b401-496a-b9e9-a160f2ab4d24",
        quoteNo: "QUO-9E6B9375-SUP",
        supplier: "40d7f4c8-e39f-4ab9-ae09-97c878e5d2d6",
        items: [
            {
                name: "Commercial media advertisement",
                quantity: "1",
                unitPrice: "60000.00",
            },
        ],
        deliverySchedule: "5 days delivery for urgent production",
        currency: "HKD",
        serviceFee: "5000.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "1500.00",
        discount: "2500.00",
        totalAmount: "64000.00",
        paymentTerms: "30% upfront, NET 30",
        receivedAt: "2020-12-06T04:04:00.000+00:00",
        createdAt: "2020-12-06T04:26:12.868+00:00",
        updatedAt: "2020-12-06T04:26:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "Valid for 30 days",
    },
    //7a0607b2-f330-4246-ae2f-8859c743977d
    {
        _id: "2d05f899-75ec-477b-a6eb-9202fbc014ea",
        quoteNo: "QUO-2D05F899-SUP",
        supplier: "458d01af-c463-4daf-9843-44aec4617d3e",
        items: [
            {
                name: "Commercial media advertisement",
                quantity: "1",
                unitPrice: "52000.00",
            },
        ],
        deliverySchedule: "4 days urgent production",
        currency: "HKD",
        serviceFee: "3000.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "600.00",
        discount: "",
        totalAmount: "55600.00",
        paymentTerms: "30% upfront, NET 30",
        receivedAt: "2020-12-03T07:00:00.000+00:00",
        createdAt: "2020-12-03T07:28:12.868+00:00",
        updatedAt: "2020-12-03T07:28:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "Valid for 30 days",
    },
    //7a0607b2-f330-4246-ae2f-8859c743977d
    {
        _id: "138a0688-00aa-433b-a7be-2b12266a90ce",
        quoteNo: "QUO-138A0688-SUP",
        supplier: "40d7f4c8-e39f-4ab9-ae09-97c878e5d2d6",
        items: [
            {
                name: "Commercial media advertisement",
                quantity: "1",
                unitPrice: "50000.00",
            },
        ],
        deliverySchedule: "5 days delivery for urgent production",
        currency: "HKD",
        serviceFee: "1000.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "800.00",
        discount: "",
        totalAmount: "51800.00",
        paymentTerms: "30% upfront, NET 30",
        receivedAt: "2020-12-06T02:23:00.000+00:00",
        createdAt: "2020-12-06T02:51:12.868+00:00",
        updatedAt: "2020-12-06T02:51:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "Valid for 30 days",
    },
    //7a0607b2-f330-4246-ae2f-8859c743977d -
    {
        _id: "81a63446-5d53-4ab6-822c-4623fb38a8fc",
        quoteNo: "QUO-81A63446-SUP",
        supplier: "fe674c7d-a786-4056-947d-d1ef75932caa",
        items: [
            {
                name: "Dresses rental daily rate",
                quantity: "2",
                unitPrice: "2500.00",
            },
        ],
        deliverySchedule: "notice 2 days in advanced",
        currency: "HKD",
        serviceFee: "",
        shippingFee: "800.00",
        tax: "0.00",
        otherCharges: "200.00",
        discount: "",
        totalAmount: "3500.00",
        paymentTerms: "30% upfront, NET 30",
        receivedAt: "2020-12-04T05:34:00.000+00:00",
        createdAt: "2020-12-04T06:09:12.868+00:00",
        updatedAt: "2020-12-04T06:09:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "Valid for 30 days",
    },
    //2f53cc82-09ac-411f-90fe-99dff9bb48c9
    {
        _id: "3a7a6529-e3be-4739-8f47-fea785b92947",
        quoteNo: "QUO-3A7A6529-SUP",
        supplier: "198fa6f1-8204-46c3-a21c-039d2a3e4d5d",
        items: [
            {
                name: "Commercial media advertisement",
                quantity: "1",
                unitPrice: "62000.00",
            },
        ],
        deliverySchedule: "notice one week in advance, standard 5 days production",
        currency: "HKD",
        serviceFee: "3000.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "500.00",
        discount: "",
        totalAmount: "65500.00",
        paymentTerms: "30% upfront, NET 30",
        receivedAt: "2020-12-11T03:07:00.000+00:00",
        createdAt: "2020-12-11T03:38:12.868+00:00",
        updatedAt: "2020-12-11T03:38:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "Valid for 30 days",
    },
    //2f53cc82-09ac-411f-90fe-99dff9bb48c9
    {
        _id: "b800de80-7f51-45da-92bb-1e1279e8404d",
        quoteNo: "QUO-B800DE80-SUP",
        supplier: "458d01af-c463-4daf-9843-44aec4617d3e",
        items: [
            {
                name: "Commercial media advertisement",
                quantity: "1",
                unitPrice: "58000.00",
            },
        ],
        deliverySchedule: "notice one week in advance, standard 5 days production",
        currency: "HKD",
        serviceFee: "1000.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "500.00",
        discount: "",
        totalAmount: "59500.00",
        paymentTerms: "30% upfront, NET 30",
        receivedAt: "2020-12-12T04:00:00.000+00:00",
        createdAt: "2020-12-12T04:21:12.868+00:00",
        updatedAt: "2020-12-12T04:21:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "Valid for 30 days",
    },
    //cd48702f-ffb5-49ef-a853-66456b3b595c
    {
        _id: "4fa0925e-ca5c-423d-a4ec-fb5a881cb05e",
        quoteNo: "QUO-4FA0925E-SUP",
        supplier: "224e190c-28ce-4f87-a79c-e977ef035f1f",
        items: [
            {
                name: "Event platform and staging daily rental",
                quantity: "5",
                unitPrice: "45000.00",
            },
            {
                name: "Event furniture chairs daily rental",
                quantity: "1000",
                unitPrice: "50.00",
            },
            {
                name: "Event furniture bench daily rental",
                quantity: "50",
                unitPrice: "150.00",
            },
            {
                name: "Misc event items daily rental",
                quantity: "5",
                unitPrice: "2500.00",
            },
        ],
        deliverySchedule: "notice one week in advance, installation included in day of delivery",
        currency: "HKD",
        serviceFee: "15000.00",
        shippingFee: "10000.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "20000.00",
        totalAmount: "300000.00",
        paymentTerms: "30% upfront with 50000 deposit, remaining due upon delivery. Deposit will be kept till rental product return, NET 30",
        receivedAt: "2020-09-04T09:06:00.000+00:00",
        createdAt: "2020-09-04T09:47:12.868+00:00",
        updatedAt: "2020-09-04T09:47:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "Valid for 30 days",
    },
    //cd48702f-ffb5-49ef-a853-66456b3b595c
    {
        _id: "68db860c-1b31-4129-a373-9d8d11d18fa3",
        quoteNo: "QUO-68DB860C-SUP",
        supplier: "a769a2ed-cd4e-4166-82aa-687947618bb8",
        items: [
            {
                name: "Mall event security - standard type I, daily rate",
                quantity: "5",
                unitPrice: "30000.00",
            },
        ],
        deliverySchedule: "",
        currency: "HKD",
        serviceFee: "5000.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "8000.00",
        totalAmount: "147000.00",
        paymentTerms: "30% upfront, remaining due upon service completion, NET 30",
        receivedAt: "2020-09-06T06:00:00.000+00:00",
        createdAt: "2020-09-06T06:32:12.868+00:00",
        updatedAt: "2020-09-06T06:32:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "Valid for 30 days",
    },
    //cd48702f-ffb5-49ef-a853-66456b3b595c
    {
        _id: "776ce7e6-cee9-440b-81ff-da23c95be3a6",
        quoteNo: "QUO-776CE7E6-SUP",
        supplier: "11139cb7-2ffa-4342-8d8f-c008a9cb433f",
        items: [
            {
                name: "Photographer service daily",
                quantity: "5",
                unitPrice: "8500.00",
            },
        ],
        deliverySchedule: "",
        currency: "HKD",
        serviceFee: "500.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "3000.00",
        totalAmount: "40000.00",
        paymentTerms: "50% upfront, NET 30",
        receivedAt: "2020-09-11T05:12:00.000+00:00",
        createdAt: "2020-09-11T05:49:12.868+00:00",
        updatedAt: "2020-09-11T05:49:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "Valid for 30 days",
    },
    //cd48702f-ffb5-49ef-a853-66456b3b595c
    {
        _id: "6419e5a6-fa5b-4ae0-87d0-9f90445361fa",
        quoteNo: "QUO-6419E5A6-SUP",
        supplier: "c754fba2-2c12-41a5-bce4-36f65a3aaeaa",
        items: [
            {
                name: "Packaging paper bags and boxes - various sizes",
                quantity: "1000",
                unitPrice: "20.00",
            },
            {
                name: "Color print paper card - A8 size",
                quantity: "1000",
                unitPrice: "2.00",
            },
        ],
        deliverySchedule: "",
        currency: "HKD",
        serviceFee: "800.00",
        shippingFee: "300.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "600.00",
        totalAmount: "22500.00",
        paymentTerms: "pay in full upfront, NET 30",
        receivedAt: "2020-09-13T05:02:00.000+00:00",
        createdAt: "2020-09-13T05:38:12.868+00:00",
        updatedAt: "2020-09-13T05:38:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "Valid for 30 days",
    },
]