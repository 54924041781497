export const campaigns = [
    {
        _id: "bbefe70a-c6a0-4ae1-8a00-ffcb25bbe391",
        campaignNo: "CAM-BBEFE70A",
        client: "1e6f3091-6acd-4999-a0d8-b4ac29cefdbd",
        name: "MUJI Winter Lifestyle Booth Campaign",
        beginAt: "2020-12-21T01:00:00.000+00:00",
        endAt: "2021-01-03T13:00:00.000+00:00",
        notes: "",
        descriptions: "Part of the MUJI Winter Lifestyle promotion, this campaign will run for 14 days through out the Christmas and New Year holiday periods." +
            "Note that booth setup must not occupy public corridor space 3 meters outside the door or we will be penalized by regulations.",
    },
    {
        _id: "35779108-e1ff-471b-aa07-1283c8d26aa0",
        campaignNo: "CAM-35779108",
        client: "1e6f3091-6acd-4999-a0d8-b4ac29cefdbd",
        name: "MUJI Winter Lifestyle Media Campaign",
        beginAt: "2020-12-10T01:00:00.000+00:00",
        endAt: "2021-01-03T13:00:00.000+00:00",
        notes: "",
        descriptions: "Part of the MUJI Winter Lifestyle promotion, this campaign aims at creating a TV advertisement for MUJI to promote their holiday sales ",
    },
    {
        _id: "11620348-7b73-4217-93b3-8fec70ac1fdd",
        campaignNo: "CAM-11620348",
        client: "1e6f3091-6acd-4999-a0d8-b4ac29cefdbd",
        name: "MUJI Winter Lifestyle Social Media Campaign",
        beginAt: "2020-12-21T01:00:00.000+00:00",
        endAt: "2021-01-03T13:00:00.000+00:00",
        notes: "",
        descriptions: "Part of the MUJI Winter Lifestyle promotion, this campaign aims at promoting MUJI's holiday sales through online social media " +
            "platforms, including instagram, facebook and google ads.",
    },
    {
        _id: "d3cabfa0-0c56-4040-bdc8-feb29ebe28b0",
        campaignNo: "CAM-D3CABFA0",
        client: "2e214095-34df-4c53-bacb-57f159ceec6d",
        name: "IKEA Smart Home Event Campaign",
        beginAt: "2021-01-02T01:00:00.000+00:00",
        endAt: "2021-01-31T13:00:00.000+00:00",
        notes: "",
        descriptions: "Part of the IKEA Smart Home Promotion, weekly event hosting (inside IKEA Megabox) every weekends for 4 weeks. This campaign aims at " +
            "promoting their recent smart home furniture and associated technologies. Staffs responsible for this campaign shall attend a two day information" +
            "section hosted by IKEA on December 17 and 18.",
    },
    {
        _id: "afc2e22c-67b0-46d1-9c35-6e8f9d3f3bb9",
        campaignNo: "CAM-AFC2E22C",
        client: "2e214095-34df-4c53-bacb-57f159ceec6d",
        name: "IKEA Smart Home Social Media Campaign",
        beginAt: "2020-12-21T01:00:00.000+00:00",
        endAt: "2021-01-31T13:00:00.000+00:00",
        notes: "",
        descriptions: "Launched along side with the IKEA Smart Home Event Campaign to promote smart home furniture and associated technologies. Social media " +
            "platform to advertise include instagram, facebook and google ads. Social media advertisement shall be up and running before Christmas holidays",
    },
    {
        _id: "a0a36bf0-a0de-4ddf-8c4b-a52e191ec42d",
        campaignNo: "CAM-A0A36BF0",
        client: "dca1a4e0-7828-4782-9b7c-d6030a775a68",
        name: "Adidas Pandemic Streaming Campaign",
        beginAt: "2021-01-02T05:00:00.000+00:00",
        endAt: "2021-01-31T04:00:00.000+00:00",
        notes: "",
        descriptions: "Part of the Adidas Pandemic Yoga Promotion, aims at promoting Adidas's yoga apparel, especially compression clothing." +
            "online Streaming with youtubers teaching yoga moves wearing Adidas's clothing every weekends after lunch during January 2021",
    },
    {
        _id: "e0f7e576-2926-4203-adeb-306f055c4809",
        campaignNo: "CAM-E0F7E576",
        client: "dca1a4e0-7828-4782-9b7c-d6030a775a68",
        name: "Adidas Alphaskin Yoga Campaign",
        beginAt: "2021-01-16T05:00:00.000+00:00",
        endAt: "2021-01-16T08:00:00.000+00:00",
        notes: "",
        descriptions: "Part of the Adidas Pandemic Yoga Promotion, free yoga training sections will be hosted in 6 districts: Tuen Mun, Yuen Long," +
            "Sha Tin, Kowloon Bay Megabox, Wan Chai and Central to promote Adidas's Alphaskin specifically for yoga exercises. Venues shall be arranged"  +
            "with Adidas before Christmas Holidays",
    },
    {
        _id: "269b55c7-e65b-4718-9d72-472d28d1ca20",
        campaignNo: "CAM-269B55C7",
        client: "dca1a4e0-7828-4782-9b7c-d6030a775a68",
        name: "Adidas Pandemic Social Media Campaign",
        beginAt: "2021-01-02T01:00:00.000+00:00",
        endAt: "2021-01-31T04:00:00.000+00:00",
        notes: "",
        descriptions: "Part of the Adidas Pandemic Yoga Promotion, social media advertisement shall be up and running on major social media platforms " +
            "including instagram, facebook and google ads on or before January 2, 2021",
    },
    {
        _id: "4257b0dd-32fb-43d6-aa95-6cda5e68a98e",
        campaignNo: "CAM-4257B0DD",
        client: "9383fc7e-7684-443f-bde9-36476db86efe",
        name: "Catalog Winter Apparel Mall Campaign",
        beginAt: "2020-12-23T01:00:00.000+00:00",
        endAt: "2020-12-23T14:00:00.000+00:00",
        notes: "",
        descriptions: "Catalog's Winter Apparel promotion event, hosted in China Hong Kong City before Christmas, aimed to promote Catalog's holiday sales. " +
            "It will be a full day event, venus has been scheduled on December 23, all materials for staging shall be coordinated with Catalog a week in advanced.",
    },
    {
        _id: "11e815c1-8176-4ef9-ad67-587fe6de7e0e",
        campaignNo: "CAM-11E815C1",
        client: "9383fc7e-7684-443f-bde9-36476db86efe",
        name: "Catalog Winter Apparel Social Media Campaign",
        beginAt: "2020-12-14T01:00:00.000+00:00",
        endAt: "2021-01-04T13:00:00.000+00:00",
        notes: "",
        descriptions: "Social media advertisement promotion for Catalog's Winter Apparel promotion. Advertisements shall be posted on major social media platforms " +
            "including instagram, facebook and google ads.",
    },
    {
        _id: "80bfc017-974c-43ce-aef5-a9b7ee86710f",
        campaignNo: "CAM-80BFC017",
        client: "45b6a3c1-c8f9-423f-95d8-133574f5538b",
        name: "COS Designer Winter Collection Media Campaign",
        beginAt: "2020-12-21T01:00:00.000+00:00",
        endAt: "2021-01-03T13:00:00.000+00:00",
        notes: "",
        descriptions: "COS has opt into contactless promotion with this campaign due to the pandemic, a TV advertisement will be tailor made for COS with two major celebrity " +
            "featured in the ad representing both genders. The advertisement shall run through out the winder holiday period. The contract is signed on " +
            "December 18 and we have limited time to get things ready, this is our prioritized campaign.",
    },
    {
        _id: "9d614d64-b48a-4fa7-bea3-5a250f7c8c22",
        campaignNo: "CAM-9D614D64",
        client: "45b6a3c1-c8f9-423f-95d8-133574f5538b",
        name: "COS Designer Winter Collection Social Media Campaign",
        beginAt: "2020-12-20T01:00:00.000+00:00",
        endAt: "2021-01-03T13:00:00.000+00:00",
        notes: "",
        descriptions: "Part of the COS Winter Collection Campaign, running social media advertisement on major platforms including Instagram, " +
            "Facebook and google ads. Content and materials are prepared by COS.",
    },
    {
        _id: "e03f672c-7544-47ed-80a4-5df85e599e74",
        campaignNo: "CAM-E03F672C",
        client: "45b6a3c1-c8f9-423f-95d8-133574f5538b",
        name: "COS Designer Winter Collection Email Campaign",
        beginAt: "2020-12-20T01:00:00.000+00:00",
        endAt: "2021-01-03T13:00:00.000+00:00",
        notes: "",
        descriptions: "Part of the COS Winter Collection Campaign, COS has decided to run a traditional email campaign due to the pandemic. Email list has " +
            "been prepared by COS ",
    },
    {
        _id: "7c6fbf5c-beee-4749-ad2b-0db1dc96d3ff",
        campaignNo: "CAM-7C6FBF5C",
        client: "d0ed9856-1f26-4b34-be6a-4bf52f2603a6",
        name: "_Julius Holiday Sales Media Campaign",
        beginAt: "2020-12-21T01:00:00.000+00:00",
        endAt: "2021-01-03T13:00:00.000+00:00",
        notes: "",
        descriptions: "Part of _Julius's Holiday Sales Campaign, a TV advertisement shall be made with two celebrities featuring both genders and the " +
            "promotion shall be live before the holidays. This campaign is second in line of priority (behind COS's campaign) as the contract is signed " +
            "on December 15. ",
    },
    {
        _id: "41516cf7-db40-4585-958f-909947e1922c",
        campaignNo: "CAM-41516CF7",
        client: "d0ed9856-1f26-4b34-be6a-4bf52f2603a6",
        name: "_Julius Holiday Sales Social Media Campaign",
        beginAt: "2020-12-21T01:00:00.000+00:00",
        endAt: "2021-01-03T13:00:00.000+00:00",
        notes: "",
        descriptions: "This social media campaign shall run along side _Julius's media campaign through out the holiday period. Social media advertisement shall " +
            "include prime celebrities as well as Instagram celebrities, posted on major social media platforms in the format of short videos and albums.",
    },
]