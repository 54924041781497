export const user = {
    _id: "a0ec763e-5084-4e1f-af64-8249c3dde67b",
    email: "jessica.chan@cinque.com.hk",
    phone: "86521234",
    hasCustomAvatar: true,
    avatar: "https://images.unsplash.com/photo-1565019011521-b0575cbb57c8?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=3900&q=80",
    initialized: false,
    name: "Jessica Chan",
    profile: {},
    role: "admin",
    twoFactorAuth: false,
    twoFactorRecoveryCodes: [],
    verified: true,
    password: "jess135246"
}