<template>
    <div id="login" class="login w-100 p-4 d-flex flex-column justify-content-center align-items-center">
        <img src="https://images.unsplash.com/photo-1518599807935-37015b9cefcb?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2550&q=80"
             alt="background" class="hero w-100 position-absolute">
        <div class="container-modal card border-0 position-relative d-flex flex-column justify-content-between align-items-center p-3">
            <h3 class="modal-title font-lobster w-100 text-center font-weight-bolder">
                Cinque
            </h3>
            <div class="container-modal-inputs w-100 text-center">
                <label for="login-email" class="modal-label position-relative d-block w-auto pl-1 text-left">Email</label>
                <input type="text" id="login-email" class="modal-input position-relative d-block px-2 mb-3 bg-white"
                       v-bind:class="{invalid: loginFail}"
                       v-model="email"
                       v-on:click="loginFail = false">
                <label for="login-password" class="modal-label position-relative d-block w-auto pl-1 text-left">Password</label>
                <input type="password" id="login-password" class="modal-input position-relative d-block px-2 mb-3 bg-white"
                       v-bind:class="{invalid: loginFail}"
                       v-model="password"
                       v-on:click="loginFail = false">
            </div>
            <button type="button" class="position-relative btn btn-primary font-weight-bolder px-4"
                    v-bind:class="{invalid: loginFail}"
                    v-on:click="login">
                Login
            </button>
        </div>
    </div>
</template>

<script>
import localforage from "localforage";
import { user } from "@/api/mockups/user";

export default {
    name: "Login",

    data() {
        return {
            email: "",
            password: "",
            loginFail: false
        }
    },

    methods: {
        async login() {
            this.loginFail = false;
            const userFound = await localforage.getItem("user");
            if (userFound) {
                //await localforage.clear();
                if (this.email === userFound.email && this.password === userFound.password) {
                    // password also acts as token in this case
                    await localforage.setItem("password", this.password);
                    await this.$router.push("/client/analytics");
                } else {
                    this.loginFail = true;
                }
            } else {
                if (this.email === user.email && this.password === user.password) {
                    // password also acts as token in this case
                    await localforage.setItem("password", this.password);
                    await this.$router.push("/client/analytics");
                } else {
                    this.loginFail = true;
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.login {
    height: 100vh;
    min-height: 300px;

    .hero {
        height: 100vh;
        min-height: 300px;
        top: 0;
        left: 0;
        object-fit: cover;
        opacity: 1;
        z-index: -10;
    }

    .container-modal {
        flex: 0 0 auto;
        position: relative;
        width: 400px;
        height: 320px;
        border-radius: 10px;
        padding: 10px 10px 25px 10px;
        background-color: rgba(233, 233, 255, 0.3);
        backdrop-filter: blur(30px);

        .modal-title {
            flex: 0 0 auto;
            height: 60px;
        }

        .container-modal-inputs {
            flex: 1 0 auto;
            height: 150px;

            .modal-label {
                font-weight: 500;
                height: 30px;
                font-size: 18px;
                margin-left: 10%;
            }

            .modal-input {
                width: 80%;
                height: 30px;
                margin-left: 10%;
                border: none;
                border-radius: 5px;
                font-size: 20px;
                color: $color-theme-text-icon;
                transition: box-shadow .3s ease;

                &.invalid {
                    box-shadow: $shadow-input-invalid;
                }

                &:focus {
                    outline: none;
                    box-shadow: $shadow-input-focus;
                }

                &:disabled {
                    background-color: $color-grey-input-light;
                }
            }
        }

        .container-modal-buttons {
            flex: 0 0 auto;
            width: 100%;
            height: 40px;

            display: flex;
            justify-content: center;
            align-items: center;

            .modal-button-confirm {
                margin-right: 50px;
                background-color: rgba(255, 100, 128, .8);
            }

            .modal-button {
                width: 100px;
                border-radius: 10px;
                font-size: 20px;

                &:disabled {
                    opacity: .5;
                    cursor: default;
                }
            }
        }

        .login-button {
            position: relative;
            width: 100px;
            height: 40px;
            border: none;
            border-radius: 10px;
            font-size: 24px;
            font-weight: 700;
            color: $color-theme-text-icon;
            box-shadow: $shadow-btn;
            cursor: pointer;
            transition: all .2s ease;

            &.invalid {
                color: $color-error;
            }

            &:focus {
                outline: none;
            }

            &:active {
                transform: translateY(3px);
                box-shadow: $shadow-btn-active;
            }
        }
    }
}
</style>
