export const journalEntries = [
    {
        _id: "e559c653-a27e-4518-8b5b-1af7bd6a3b0b",
        jeNo: "JE-E559C653",
        debits: [
            {
                account: "E-Banner Hong Kong AC-6E85889F",
                amount: "7050.00",
            },
        ],
        credits: [
            {
                account: "Bank AC-9F5ED15C",
                amount: "7050.00",
            },
        ],
        // ignore time zone
        date: "2020-12-04T00:00:00.000+00:00",
        sum: "7050.00",
        descriptions: "INV-4027D7B6-PUR, deposit for PO-9A745E0D",
        notes: "",
        createdAt: "2020-12-04T08:52:12.000+00:00",
        updatedAt: "2020-12-04T08:52:12.000+00:00",
        createdBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
    },
    {
        _id: "ddf59560-fd31-4591-b771-6133f75053e0",
        jeNo: "JE-DDF59560",
        debits: [
            {
                account: "90 Seconds AC-40D7F4C8",
                amount: "19050.00",
                
            },
        ],
        credits: [
            {
                account: "Bank AC-9F5ED15C",
                amount: "19050.00",
            },
        ],
        // ignore time zone
        date: "2020-12-03T00:00:00.000+00:00",
        sum: "19050.00",
        descriptions: "INV-0912AD63-PUR, deposit for PO-51B30AEB",
        notes: "",
        createdAt: "2020-12-03T06:17:00.000+00:00",
        updatedAt: "2020-12-03T06:17:00.000+00:00",
        createdBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
    },
    {
        _id: "8ada2bcc-a66b-4234-bedd-b080a58c3ccd",
        jeNo: "JE-8ADA2BCC",
        debits: [
            {
                account: "E-Banner Hong Kong AC-6E85889F",
                amount: "2940.00",
            },
        ],
        credits: [
            {
                account: "Bank AC-9F5ED15C",
                amount: "2940.00",
            },
        ],
        // ignore time zone
        date: "2020-12-22T00:00:00.000+00:00",
        sum: "2940.00",
        descriptions: "INV-77B54756-PUR, deposit for PO-9400884F",
        notes: "",
        createdAt: "2020-12-22T08:36:12.00+00:00",
        updatedAt: "2020-12-22T08:36:12.00+00:00",
        createdBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
    },
    {
        _id: "8f8c7396-a221-49b0-bb9f-6ca83648943a",
        jeNo: "JE-8F8C7396",
        debits: [
            {
                account: "Yoga Room AC-99119E65",
                amount: "14500.00",
            },
        ],
        credits: [
            {
                account: "Bank AC-9F5ED15C",
                amount: "14500.00",
            },
        ],
        // ignore time zone
        date: "2020-12-21T00:00:00.000+00:00",
        sum: "14500.00",
        descriptions: "INV-95F48A73-PUR, deposit for PO-E9702274",
        notes: "",
        createdAt: "2020-12-21T07:22:12.000+00:00",
        updatedAt: "2020-12-21T07:22:12.000+00:00",
        createdBy: "907368cd-ee6b-4743-815f-2a634605cf72907368cd-ee6b-4743-815f-2a634605cf72",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72907368cd-ee6b-4743-815f-2a634605cf72",
    },
    {
        _id: "3fb9ab9e-3a36-4dfc-9218-753e656e81f5",
        jeNo: "JE-3FB9AB9E",
        debits: [
            {
                account: "Eagle Focus AC-224E190C",
                amount: "17400.00",
            },
        ],
        credits: [
            {
                account: "Bank AC-9F5ED15C",
                amount: "17400.00",
            },
        ],
        // ignore time zone
        date: "2020-12-11T00:00:00.000+00:00",
        sum: "17400.00",
        descriptions: "INV-2C388708-PUR, deposit for PO-A6F49D1C",
        notes: "",
        createdAt: "2020-12-11T05:23:12.000+00:00",
        updatedAt: "2020-12-11T05:23:12.000+00:00",
        createdBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
    },
    {
        _id: "c567518f-7762-4c83-8076-af82409d8623",
        jeNo: "JE-C567518F",
        debits: [
            {
                account: "Guardforce Limited AC-A769A2ED",
                amount: "7950.00",
            },
        ],
        credits: [
            {
                account: "Bank AC-9F5ED15C",
                amount: "7950.00",
            },
        ],
        // ignore time zone
        date: "2020-12-11T00:00:00.000+00:00",
        sum: "7950.00",
        descriptions: "INV-B67D5E9A-PUR, deposit for PO-C4CE5550",
        notes: "",
        createdAt: "2020-12-11T06:02:12.000+00:00",
        updatedAt: "2020-12-11T06:02:12.000+00:00",
        createdBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
    },
    {
        _id: "a27aab57-e6b7-4ea7-9f59-e39995f21bf4",
        jeNo: "JE-A27AAB57",
        debits: [
            {
                account: "SENS Studio Ltd AC-11139CB7",
                amount: "4250.00",
            },
        ],
        credits: [
            {
                account: "Bank AC-9F5ED15C",
                amount: "4250.00",
            },
        ],
        // ignore time zone
        date: "2020-12-11T00:00:00.000+00:00",
        sum: "4250.00",
        descriptions: "INV-29898C09-PUR, deposit for PO-A5C9E7C6",
        notes: "",
        createdAt: "2020-12-11T04:16:12.000+00:00",
        updatedAt: "2020-12-11T04:16:12.000+00:00",
        createdBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
    },
    {
        _id: "07f09199-5087-4566-ae57-11799a34530a",
        jeNo: "JE-07F09199",
        debits: [
            {
                account: "Be Tabula Rasa AC-795990B8",
                amount: "6500.00",
            },
        ],
        credits: [
            {
                account: "Cash AC-58F98C86",
                amount: "6500.00",
            },
        ],
        // ignore time zone
        date: "2020-12-23T00:00:00.000+00:00",
        sum: "6500.00",
        descriptions: "INV-781FEDBB-PUR, full amount for PO-C5D6E4C5",
        notes: "",
        createdAt: "2020-12-23T05:45:00.000+00:00",
        updatedAt: "2020-12-23T05:45:00.000+00:00",
        createdBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
    },
    {
        _id: "86cd0f75-9bc2-4242-a80b-d8ecaef1a254",
        jeNo: "JE-86CD0F75",
        debits: [
            {
                account: "Tai Yan Printing Co. AC-C754FBA2",
                amount: "4000.00",
            },
        ],
        credits: [
            {
                account: "Cash AC-58F98C86",
                amount: "4000.00",
            },
        ],
        // ignore time zone
        date: "2020-12-14T00:00:00.000+00:00",
        sum: "4000.00",
        descriptions: "INV-99C5659D-PUR, full amount for PO-0FB80A7A",
        notes: "",
        createdAt: "2020-12-14T05:49:12.000+00:00",
        updatedAt: "2020-12-14T05:49:12.000+00:00",
        createdBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
    },
    {
        _id: "01d29f74-e5f8-4f28-a963-8ad3b501d845",
        jeNo: "JE-01D29F74",
        debits: [
            {
                account: "Visual Suspect AC-198FA6F1",
                amount: "24450.00",
            },
        ],
        credits: [
            {
                account: "Bank AC-9F5ED15C",
                amount: "24450.00",
            },
        ],
        // ignore time zone
        date: "2020-12-18T00:00:00.000+00:00",
        sum: "24450.00",
        descriptions: "INV-F73A5350-PUR, deposit for PO-9ACC6ABA",
        notes: "",
        createdAt: "2020-12-18T08:06:34.000+00:00",
        updatedAt: "2020-12-18T08:06:34.000+00:00",
        createdBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
    },
    {
        _id: "3c562ccd-6e9c-475b-8658-b03239600a10",
        jeNo: "JE-3C562CCD",
        debits: [
            {
                account: "90 Seconds AC-40D7F4C8",
                amount: "15540.00",
            },
        ],
        credits: [
            {
                account: "Bank AC-9F5ED15C",
                amount: "15540.00",
            },
        ],
        // ignore time zone
        date: "2020-12-16T00:00:00.000+00:00",
        sum: "15540.00",
        descriptions: "INV-E4742E31-PUR, deposit for PO-56CDE022",
        notes: "",
        createdAt: "2020-12-16T04:22:12.000+00:00",
        updatedAt: "2020-12-16T04:22:12.000+00:00",
        createdBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
    },
    {
        _id: "3d5dc8b7-261e-4cfe-a98b-2ccb344653fa",
        jeNo: "JE-3D5DC8B7",
        debits: [
            {
                account: "Pret-a-Dress AC-FE674C7D",
                amount: "1750.00",
            },
        ],
        credits: [
            {
                account: "Cash AC-58F98C86",
                amount: "1750.00",
            },
        ],
        // ignore time zone
        date: "2020-12-16T00:00:00.000+00:00",
        sum: "1750.00",
        descriptions: "INV-ADF43B45-PUR, deposit for PO-5CCDC187",
        notes: "",
        createdAt: "2020-12-16T05:55:12.000+00:00",
        updatedAt: "2020-12-16T05:55:12.000+00:00",
        createdBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
    },
    {
        _id: "d9ed2703-83ca-4ede-9159-9f95823626c4",
        jeNo: "JE-D9ED2703",
        debits: [
            {
                account: "Expenditure AC-27978A39",
                amount: "3500.00",
            }
        ],
        credits: [
            {
                account: "Pret-a-Dress AC-FE674C7D",
                amount: "1750.00",
            },
            {
                account: "Cash AC-58F98C86",
                amount: "1750.00",
            },
        ],
        // ignore time zone
        date: "2020-12-21T00:00:00.000+00:00",
        sum: "1750.00",
        descriptions: "deposit (INV-ADF43B45-PUR) and remaining balance (INV-5555CD39-PUR) for PO-5CCDC187",
        notes: "",
        createdAt: "2020-12-21T05:43:00.000+00:00",
        updatedAt: "2020-12-21T05:43:00.000+00:00",
        createdBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
    },
    {
        _id: "e9a939af-0fbd-496e-a47f-fd4a238aa1d4",
        jeNo: "JE-E9A939AF",
        debits: [
            {
                account: "Cash AC-58F98C86",
                amount: "46170.00",
            },
        ],
        credits: [
            {
                account: "MUJI Harbour City AC-1E6F3091",
                amount: "46170.00",
                
            },
        ],
        // ignore time zone
        date: "2020-12-02T00:00:00.000+00:00",
        sum: "46170.00",
        descriptions: "INV-7E794BB1-SAL, paid deposit for SO-3DAB44F7",
        notes: "",
        createdAt: "2020-12-02T08:39:12.000+00:00",
        updatedAt: "2020-12-02T08:39:12.000+00:00",
        createdBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
    },
    {
        _id: "dd1c9f6a-3322-42ab-afa3-029211de8f05",
        jeNo: "JE-DD1C9F6A",
        debits: [
            {
                account: "Cash AC-58F98C86",
                amount: "45000.00",
            },
        ],
        credits: [
            {
                account: "IKEA Kowloon Bay AC-2E214095",
                amount: "45000.00",
            },
        ],
        // ignore time zone
        date: "2020-12-14T00:00:00.000+00:00",
        sum: "45000.00",
        descriptions: "INV-CFA75FBB-SAL, paid deposit for SO-125ED4D4",
        notes: "",
        createdAt: "2020-12-14T05:58:00.000+00:00",
        updatedAt: "2020-12-14T05:58:00.000+00:00",
        createdBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
    },
    {
        _id: "831aa718-3224-4d3e-b523-25382e391c86",
        jeNo: "JE-831AA718",
        debits: [
            {
                account: "Cash AC-58F98C86",
                amount: "72000.00",
            },
        ],
        credits: [
            {
                account: "Adidas Outlet Store AC-DCA1A4E0",
                amount: "72000.00",
            },
        ],
        // ignore time zone
        date: "2020-12-21T00:00:00.000+00:00",
        sum: "72000.00",
        descriptions: "INV-CCA16343-SAL, paid deposit for SO-9CC82FD0",
        notes: "",
        createdAt: "2020-12-21T04:45:00.000+00:00",
        updatedAt: "2020-12-21T04:45:00.000+00:00",
        createdBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
    },
    {
        _id: "78db8cf1-c17f-4eab-9358-c099bf3b87c2",
        jeNo: "JE-78DB8CF1",
        debits: [
            {
                account: "Cash AC-58F98C86",
                amount: "51000.00",
            },
        ],
        credits: [
            {
                account: "Catalog AC-9383FC7E",
                amount: "51000.00",
            },
        ],
        // ignore time zone
        date: "2020-12-11T00:00:00.000+00:00",
        sum: "51000.00",
        descriptions: "INV-DF38045D-SAL, paid deposit for SO-0C3EB67D",
        notes: "",
        createdAt: "2020-12-11T03:26:12.000+00:00",
        updatedAt: "2020-12-11T03:26:12.000+00:00",
        createdBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
    },
    {
        _id: "343c076c-34d4-4c8b-b8e6-5d3c24786f0e",
        jeNo: "JE-343C076C",
        debits: [
            {
                account: "Cash AC-58F98C86",
                amount: "42750.00",
            },
        ],
        credits: [
            {
                account: "COS Central AC-45B6A3C1",
                amount: "42750.00",
            },
        ],
        // ignore time zone
        date: "2020-12-18T00:00:00.000+00:00",
        sum: "42750.00",
        descriptions: "INV-CA0DB6C9-SAL, paid deposit for SO-E4FD32E2",
        notes: "",
        createdAt: "2020-12-18T08:51:12.000+00:00",
        updatedAt: "2020-12-18T08:51:12.000+00:00",
        createdBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
    },
    {
        _id: "8f65d948-44a9-4816-9d10-d1b705e6a328",
        jeNo: "JE-8F65D948",
        debits: [
            {
                account: "Cash AC-58F98C86",
                amount: "45900.00",
            },
        ],
        credits: [
            {
                account: "_Julius Hong Kong AC-D0ED9856",
                amount: "45900.00",
            },
        ],
        // ignore time zone
        date: "2020-12-16T00:00:00.000+00:00",
        sum: "45900.00",
        descriptions: "INV-03B84F62-SAL, paid deposit for SO-5CB834F0",
        notes: "",
        createdAt: "2020-12-16T02:48:12.000+00:00",
        updatedAt: "2020-12-16T02:48:12.000+00:00",
        createdBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
    },
    {
        _id: "c6052c07-4285-4a43-8d8b-a2ad0579b4ff",
        jeNo: "JE-C6052C07",
        debits: [
            {
                account: "Commission Expenses AC-1FFF1EF5",
                amount: "4800.00",
            },
        ],
        credits: [
            {
                account: "Cash AC-58F98C86",
                amount: "4800.00",
            },
        ],
        // ignore time zone
        date: "2020-12-22T00:00:00.000+00:00",
        sum: "4800.00",
        descriptions: "Commission to Rabbit Studio AC-511BBC39 for client(Adidas) referral",
        notes: "",
        createdAt: "2020-12-22T04:36:19.000+00:00",
        updatedAt: "2020-12-22T04:36:19.000+00:00",
        createdBy: "907368cd-ee6b-4743-815f-2a634605cf72",
        updatedBy: "907368cd-ee6b-4743-815f-2a634605cf72",
    },
]