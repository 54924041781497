import Vue from 'vue'
import VueRouter from 'vue-router'

// Utility
import { authenticate, logout, verifyId } from "@/router/router";
// Vue components
import Login from "@/components/page/Login";
import RouteWrapper from "@/components/page/RouteWrapper";
import Panel from "@/components/page/Panel";

Vue.use(VueRouter)

const routes = [
    {
        path: "/login",
        component: Login
    },
    {
        path: "/logout",
        beforeEnter: logout,
        redirect: "/login"
    },
    {
        path: "/",
        beforeEnter: authenticate,
        component: RouteWrapper,
        children: [
            {
                path: "",
                redirect: "/client/analytics"
            },
            {
                path: "user",
                component: Panel,
                children: [
                    {
                        path: "",
                        component: () => import("../components/user/UserDetails"),
                        meta: { title: "User", subTitle: "Profile Details" }
                    },
                ]
            },
            {
                path: "client",
                component: Panel,
                children: [
                    {
                        path: "",
                        component: () => import("../components/client-management/client/ClientTable"),
                        meta: { title: "Clients", subTitle: "Client" }
                    },
                    {
                        path: "analytics",
                        component: () => import("../components/client-management/analytics/RevenuePie"),
                        meta: { title: "Clients", subTitle: "Sales Revenue Analytics" }
                    },
                    {
                        path: "add",
                        component: () => import("../components/client-management/client/ClientDetails"),
                        meta: { title: "Clients", subTitle: "New Client" },
                    },
                    {
                        path: ":clientId",
                        beforeEnter: (to, from, next) => verifyId("clients", to.params.clientId, "/client", next),
                        component: () => import("../components/client-management/client/ClientDetails"),
                        meta: { title: "Clients", subTitle: "Client Details" },
                        props: true
                    },
                ]
            },
            {
                path: "sales",
                component: Panel,
                children: [
                    {
                        path: "",
                        redirect: "/sales/analytics"
                    },
                    {
                        path: "analytics",
                        component: () => import("../components/sales-management/analytics/SalesFunnel"),
                        meta: { title: "Sales", subTitle: "Sales Funnel" }
                    },
                    {
                        path: "quotation",
                        component: () => import("../components/sales-management/quotation/SalesQuoteTable"),
                        meta: { title: "Sales", subTitle: "Quotation" }
                    },
                    {
                        path: "quotation/add",
                        component: () => import("../components/sales-management/quotation/SalesQuoteDetails"),
                        meta: { title: "Sales", subTitle: "New Quote" }
                    },
                    {
                        path: "quotation/:salesQuoteId",
                        beforeEnter: (to, from, next) => verifyId("salesQuotations", to.params.salesQuoteId, "/sales/quotation", next),
                        component: () => import("../components/sales-management/quotation/SalesQuoteDetails"),
                        meta: { title: "Sales", subTitle: "Quote Details" },
                        props: true
                    },
                    {
                        path: "order",
                        component: () => import("../components/sales-management/order/SalesOrderTable"),
                        meta: { title: "Sales", subTitle: "Order" }
                    },
                    {
                        path: "order/add",
                        component: () => import("../components/sales-management/order/SalesOrderDetails"),
                        meta: { title: "Sales", subTitle: "New Sales Order" }
                    },
                    {
                        path: "order/:salesOrderId",
                        beforeEnter: (to, from, next) => verifyId("salesOrders", to.params.salesOrderId, "/sales/order", next),
                        component: () => import("../components/sales-management/order/SalesOrderDetails"),
                        meta: { title: "Sales", subTitle: "Sales Order Details" },
                        props: true
                    },
                    {
                        path: "partner",
                        component: () => import("../components/sales-management/partner/SalesPartnerTable"),
                        meta: { title: "Sales", subTitle: "Sales Partner" }
                    },
                    {
                        path: "partner/add",
                        component: () => import("../components/sales-management/partner/SalesPartnerDetails"),
                        meta: { title: "Sales", subTitle: "New Sales Partner" }
                    },
                    {
                        path: "partner/:partnerId",
                        beforeEnter: (to, from, next) => verifyId("salesPartners", to.params.partnerId, "/sales/partner", next),
                        component: () => import("../components/sales-management/partner/SalesPartnerDetails"),
                        meta: { title: "Sales", subTitle: "Sales Partner Details" },
                        props: true
                    },
                ]
            },
            {
                path: "supplier",
                component: Panel,
                children: [
                    {
                        path: "",
                        component: () => import("../components/supplier-management/supplier/SupplierTable"),
                        meta: { title: "Suppliers", subTitle: "Supplier" }
                    },

                    {
                        path: "request",
                        component: () => import("../components/supplier-management/request/QuoteRequestTable"),
                        meta: { title: "Suppliers", subTitle: "Quotation Request" }
                    },
                    {
                        path: "request/add",
                        component: () => import("../components/supplier-management/request/QuoteRequestDetails"),
                        meta: { title: "Suppliers", subTitle: "New Request" }
                    },
                    {
                        path: "request/:requestId",
                        beforeEnter: (to, from, next) => verifyId("quotationRequests", to.params.requestId, "/supplier/request", next),
                        component: () => import("../components/supplier-management/request/QuoteRequestDetails"),
                        meta: { title: "Suppliers", subTitle: "Request Details" },
                        props: true
                    },
                    {
                        path: "quotation",
                        component: () => import("../components/supplier-management/quotation/SupplierQuoteTable"),
                        meta: { title: "Suppliers", subTitle: "Quotation" }
                    },
                    {
                        path: "quotation/add",
                        component: () => import("../components/supplier-management/quotation/SupplierQuoteDetails"),
                        meta: { title: "Suppliers", subTitle: "New Quote" }
                    },
                    {
                        path: "quotation/:supplierQuoteId",
                        beforeEnter: (to, from, next) => verifyId("supplierQuotations", to.params.supplierQuoteId, "/supplier/quotation", next),
                        component: () => import("../components/supplier-management/quotation/SupplierQuoteDetails"),
                        meta: { title: "Suppliers", subTitle: "Quote Details" },
                        props: true
                    },
                    {
                        path: "order",
                        component: () => import("../components/supplier-management/order/PurchaseOrderTable"),
                        meta: { title: "Suppliers", subTitle: "Purchase Order" }
                    },
                    {
                        path: "order/add",
                        component:() => import("../components/supplier-management/order/PurchaseOrderDetails"),
                        meta: { title: "Suppliers", subTitle: "New Purchase Order" }
                    },
                    {
                        path: "order/:purchaseOrderId",
                        beforeEnter: (to, from, next) => verifyId("purchaseOrders", to.params.purchaseOrderId, "/supplier/order", next),
                        component: () => import("../components/supplier-management/order/PurchaseOrderDetails"),
                        meta: { title: "Suppliers", subTitle: "Purchase Order Details" },
                        props: true
                    },
                    {
                        path: "document",
                        component: () => import("../components/supplier-management/document/DocumentTable"),
                        meta: { title: "Suppliers", subTitle: "Document" }
                    },
                    {
                        path: "document/add",
                        component: () => import("../components/supplier-management/document/DocumentDetails"),
                        meta: { title: "Suppliers", subTitle: "New Document" }
                    },
                    {
                        path: "document/:documentId",
                        beforeEnter: (to, from, next) => verifyId("documents", to.params.documentId, "/supplier/document", next),
                        component: () => import("../components/supplier-management/document/DocumentDetails"),
                        meta: { title: "Suppliers", subTitle: "Document Details" },
                        props: true
                    },

                    {
                        path: "record",
                        component: () => import("../components/supplier-management/record/RecordTable"),
                        meta: { title: "Suppliers", subTitle: "Delivery Records" }
                    },
                    {
                        path: "record/add",
                        component: () => import("../components/supplier-management/record/RecordDetails"),
                        meta: { title: "Suppliers", subTitle: "New Delivery Record" }
                    },
                    {
                        path: "record/:recordId",
                        beforeEnter: (to, from, next) => verifyId("deliveryRecords", to.params.recordId, "/supplier/record", next),
                        component: () => import("../components/supplier-management/record/RecordDetails"),
                        meta: { title: "Suppliers", subTitle: "Record Details" },
                        props: true,
                    },

                    {
                        path: "add",
                        component: () => import("../components/supplier-management/supplier/SupplierDetails"),
                        meta: { title: "Suppliers", subTitle: "New Supplier" }
                    },
                    {
                        path: ":supplierId",
                        beforeEnter: (to, from, next) => verifyId("suppliers", to.params.supplierId, "/supplier", next),
                        component: () => import("../components/supplier-management/supplier/SupplierDetails"),
                        meta: { title: "Suppliers", subTitle: "Supplier Details" },
                        props: true
                    },
                ]
            },
            {
                path: "project",
                component: Panel,
                children: [
                    {
                        path: "",
                        redirect: "/project/progress"
                    },
                    {
                        path: "progress",
                        component: () => import("../components/project-management/progress/ProgressReport"),
                        meta: { title: "Projects", subTitle: "Progress" }
                    },
                    {
                        path: "task",
                        component: () => import("../components/project-management/task/TaskTable"),
                        meta: { title: "Projects", subTitle: "Task" }
                    },
                    {
                        path: "task/add",
                        component: () => import("../components/project-management/task/TaskDetails"),
                        meta: { title: "Projects", subTitle: "New Task" }
                    },
                    {
                        path: "task/:taskId",
                        beforeEnter: (to, from, next) => verifyId("tasks", to.params.taskId, "/project/task", next),
                        component: () => import("../components/project-management/task/TaskDetails"),
                        meta: { title: "Projects", subTitle: "Task Details" },
                        props: true
                    },
                    {
                        path: "campaign",
                        component: () => import("../components/project-management/campaign/CampaignTable"),
                        meta: { title: "Projects", subTitle: "Campaign" }
                    },
                    {
                        path: "campaign/add",
                        component: () => import("../components/project-management/campaign/CampaignDetails"),
                        meta: { title: "Projects", subTitle: "New Campaign" }
                    },
                    {
                        path: "campaign/:campaignId",
                        beforeEnter: (to, from, next) => verifyId("campaigns", to.params.campaignId, "/project/campaign", next),
                        component: () => import("../components/project-management/campaign/CampaignDetails"),
                        meta: { title: "Projects", subTitle: "Campaign Details" },
                        props: true
                    },
                    {
                        path: "staff",
                        component: () => import("../components/project-management/staff/StaffTable"),
                        meta: { title: "Projects", subTitle: "Staff" }
                    },
                    {
                        path: "staff/add",
                        component: () => import("../components/project-management/staff/StaffDetails"),
                        meta: { title: "Projects", subTitle: "New Staff" }
                    },
                    {
                        path: "staff/:staffId",
                        beforeEnter: (to, from, next) => verifyId("staffs", to.params.staffId, "/project/staff", next),
                        component: () => import("../components/project-management/staff/StaffDetails"),
                        meta: { title: "Projects", subTitle: "Staff Details" },
                        props: true
                    },
                ]
            },
            {
                path: "accounting",
                component: Panel,
                children: [
                    {
                        path: "",
                        redirect: "/accounting/journal"
                    },
                    {
                        path: "journal",
                        component: () => import("../components/accounting/journal/JournalTable"),
                        meta: { title: "Accounting", subTitle: "Journal" }
                    },
                    {
                        path: "journal/add",
                        component: () => import("../components/accounting/journal/JournalDetails"),
                        meta: { title: "Accounting", subTitle: "New Journal" }
                    },
                    {
                        path: "journal/:journalId",
                        beforeEnter: (to, from, next) => verifyId("journalEntries", to.params.journalId, "/accounting/journal", next),
                        component: () => import("../components/accounting/journal/JournalDetails"),
                        meta: { title: "Accounting", subTitle: "Journal Details" },
                        props: true
                    },
                    {
                        path: "sales-invoice",
                        component: () => import("../components/accounting/sales/SalesInvoiceTable"),
                        meta: { title: "Accounting", subTitle: "Sales Invoice" }
                    },
                    {
                        path: "sales-invoice/add",
                        component: () => import("../components/accounting/sales/SalesInvoiceDetails"),
                        meta: { title: "Accounting", subTitle: "New Sales Invoice" }
                    },
                    {
                        path: "sales-invoice/:salesInvoiceId",
                        beforeEnter: (to, from, next) => verifyId("salesInvoices", to.params.salesInvoiceId, "/accounting/sales-invoice", next),
                        component: () => import("../components/accounting/sales/SalesInvoiceDetails"),
                        meta: { title: "Accounting", subTitle: "Sales Invoice Details" },
                        props: true
                    },
                    {
                        path: "purchase-invoice",
                        component:() => import("../components/accounting/purchase/PurchaseInvoiceTable"),
                        meta: { title: "Accounting", subTitle: "Purchase Invoice" }
                    },
                    {
                        path: "purchase-invoice/add",
                        component: () => import("../components/accounting/purchase/PurchaseInvoiceDetails"),
                        meta: { title: "Accounting", subTitle: "New Purchase Invoice" }
                    },
                    {
                        path: "purchase-invoice/:purchaseInvoiceId",
                        beforeEnter: (to, from, next) => verifyId("purchaseInvoices", to.params.purchaseInvoiceId, "/accounting/purchase-invoice", next),
                        component: () => import("../components/accounting/purchase/PurchaseInvoiceDetails"),
                        meta: { title: "Accounting", subTitle: "Purchase Invoice Details" },
                        props: true
                    },
                ]
            },
        ]
    },
    {
        path: "*",
        redirect: "/client/analytics"
    },
]

const router = new VueRouter({
    mode: "hash",
    routes
})

export default router;
