<template>
    <div class="panel position-relative w-100 d-flex flex-column overflow-hidden">
        <sidebar v-bind:nav-bar-hidden="navBarHidden"
                 v-bind:user="user"
                 v-bind:panels="panels"
                 v-on:logout="logout"
                 v-on:hide-nav-bar="navBarHidden = true">
        </sidebar>

        <div class="panel-overlay position-absolute w-100 h-100"
             v-show="!navBarHidden"
             v-on:click="navBarHidden = true">
        </div>
        <div class="panel-titles py-2 px-4 position-relative w-100 d-flex align-items-center">
            <div class="nav-button position-relative mr-5 d-flex justify-content-center align-items-center"
                 v-on:click="navBarHidden = false">
                <font-awesome-icon class="nav-icon position-relative" v-bind:icon="['fas', 'bars']"></font-awesome-icon>
            </div>
            <h1 class="main-title w-auto h-100 pr-4 font-weight-bolder text-uppercase m-0">
                {{ title }}
            </h1>
            <div class="sub-titles w-auto h-100 d-flex justify-content-start align-items-center">
                <b-dropdown class="px-4"
                            variant="link">
                    <template v-slot:button-content>
                        <h4 class="d-inline-block font-weight-normal text-nowrap mb-0 mr-2">
                            {{ subTitle }}
                        </h4>
                    </template>
                    <b-dropdown-item-button v-for="{ title, path } in panel.subPanels"
                                            v-bind:key="path"
                                            v-on:click="$router.push(path)">
                        {{ title }}
                    </b-dropdown-item-button>
                </b-dropdown>
            </div>
        </div>
        <transition mode="out-in">
            <keep-alive v-bind:exclude="excluded">
                <router-view></router-view>
            </keep-alive>
        </transition>
    </div>
</template>

<script>
import {
    BDropdown, BDropdownItemButton,
} from "bootstrap-vue"

import Sidebar from "@/components/functional/navigation/Sidebar";
import { panels } from "@/api";

export default {
    name: "Panel",
    components: {
        BDropdown, BDropdownItemButton,
        Sidebar,
    },
    data() {
        return {
            navBarHidden: true,
            panels,
	        excluded: [
	        	"ClientDetails",
		        "SalesQuoteDetails", "SalesOrderDetails", "SalesPartnerDetails", "SalesInvoiceDetails",
		        "SupplierDetails", "QuoteRequestDetails", "SupplierQuoteDetails", "PurchaseOrderDetails", "PurchaseInvoiceDetails",
		        "RecordDetails",
		        "TaskDetails", "CampaignDetails", "StaffDetails",
		        "JournalDetails",
		        "DocumentDetails",
		        "UserDetails"
	        ],
        }
    },
    
    computed: {
        user () {
            return this.$store.state.user.user;
        },

        panel () {
            return panels.find(
                ({ title }) => title === this.title
            );
        },

        title () {
            return this.$route.meta.title ?? "";
        },
        
        subTitle () {
            return this.$route.meta.subTitle ?? "";
        }
    },
	
	methods: {
		async logout() {
			this.navBarHidden = true;
			await this.$router.push("/logout");
		}
	}
}
</script>

<style lang="scss" scoped>
.panel {
    height: 100vh;
    min-height: 510px;
    
    .panel-overlay {
        top: 0;
        left: 0;
        z-index: 99;
    }
    
    .panel-titles {
        flex: 0 0 auto;
        height: 70px;
        user-select: none;
        
        .nav-button {
            flex: 0 0 auto;
            position: relative;
            width: 48px;
            height: 48px;
            border-radius: 100px;
            cursor: pointer;
            transition: all .3s ease;
            
            &:hover {
                background-color: rgba(0, 0, 0, .1);
            }
            
            .nav-icon {
                width: 25px;
                height: 25px;
            }
        }
    }
}
</style>
