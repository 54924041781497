import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {BFormDatepicker, BFormTimepicker, BCalendar} from "bootstrap-vue";
Vue.config.productionTip = false

import "./init/font-awesome";
import {
  FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";
Vue.component("font-awesome-icon", FontAwesomeIcon);

export const eventBus = new Vue();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
