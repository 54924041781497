import localforage from "localforage";

const state = {
    // change them back to null in production
    user: {},
}

const mutations = {
    updateUserMutate: (state, user) => {
        state.user = user;
    },
}
const actions = {
    async updateUser ({ commit }, user) {
        commit("updateUserMutate", user);
        await localforage.setItem("user", state.user);
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}