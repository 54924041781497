<template>
	<div id="app">
		<transition name="fade" mode="out-in">
			<router-view></router-view>
		</transition>
	</div>
</template>

<script>
export default {
	name: "App"
}
</script>

<style lang="scss">
// base
*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
	font-size: 16px;
	min-width: 600px;
	overflow-x: hidden;
	scroll-behavior: smooth;
	
	&::-webkit-scrollbar {
		width: 8px;
	}
	
	&::-webkit-scrollbar-track {
		background-color: white;
	}
	
	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: $color-grey-button;
	}
}

body {
	font-family: $font-EN;
}

// vue page transition
.fade-enter-active,
.fade-leave-active {
	transition: opacity .2s ease;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}

// custom classes
.scroll-bar {
	&::-webkit-scrollbar {
		width: 8px;
	}
	
	&::-webkit-scrollbar-track {
		background-color: transparent;
	}
	
	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: rgba(0, 0, 0, .25);
	}
	
	&::-webkit-scrollbar-corner {
		opacity: 0;
	}
}

// bootstrap sass variable overwrite
$body-bg: $color-theme-background;
$body-color: $color-theme-text-icon;

$theme-colors: (
	"danger": $color-error,
	"custom-light": $color-theme-text-icon-light,
	"custom": $color-theme-text-icon,
);

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-vue/src/index";

.font-lobster {
    font-family: "Lobster", cursive;
}
</style>
