import { user } from "./mockups/user"
import { staffs } from "./mockups/staffs"
import { clients } from "./mockups/clients"
import { salesPartners } from "./mockups/salesPartners"
import { suppliers } from "./mockups/suppliers"
import { quotationRequests } from "./mockups/quotationRequests"
import { supplierQuotations } from "./mockups/supplierQuotations"
import { purchaseOrders } from "./mockups/purchaseOrders"
import { purchaseInvoices } from "./mockups/purchaseInvoices"
import { salesQuotations } from "./mockups/salesQuotations"
import { salesOrders } from "./mockups/salesOrders"
import { salesInvoices } from "./mockups/salesInvoices"
import { campaigns } from "./mockups/campaigns"
import { tasks } from "./mockups/tasks"
import { journalEntries } from "./mockups/journalEntries"
import { documents } from "@/api/mockups/documents";
import { deliveryRecords } from "@/api/mockups/deliveryRecords";

export const panels = [
    {
        title: "Clients",
        subPanels: [
            { title: "Analytics", path: "/client/analytics" },
            { title: "Client", path: "/client" },
        ]
    },
    {
        title: "Sales",
        subPanels: [
            { title: "Analytics", path: "/sales/analytics" },
            { title: "Quotation", path: "/sales/quotation" },
            { title: "Order", path: "/sales/order" },
            { title: "Sales Partner", path: "/sales/partner" },
        ]
    },
    {
        title: "Suppliers",
        subPanels: [
            { title: "Supplier", path: "/supplier" },
            { title: "Quotation Request", path: "/supplier/request" },
            { title: "Quotation", path: "/supplier/quotation" },
            { title: "Purchase Order", path: "/supplier/order" },
            { title: "Document", path: "/supplier/document" },
            { title: "Delivery Record", path: "/supplier/record" }
        ]
    },
    {
        title: "Projects",
        subPanels: [
            { title: "Progress", path: "/project/progress" },
            { title: "Task", path: "/project/task" },
            { title: "Campaign", path: "/project/campaign" },
            { title: "Staff", path: "/project/staff" },
        ]
    },
    {
        title: "Accounting",
        subPanels: [
            { title: "Journal", path: "/accounting/journal" },
            { title: "Sales Invoice", path: "/accounting/sales-invoice" },
            { title: "Purchase Invoice", path: "/accounting/purchase-invoice" }
        ]
    }
]

export default {
    user,
    staffs,
    clients,
    salesPartners,
    suppliers,
    quotationRequests,
    supplierQuotations,
    purchaseOrders,
    purchaseInvoices,
    salesQuotations,
    salesOrders,
    salesInvoices,
    campaigns,
    tasks,
    journalEntries,
    documents,
    deliveryRecords,
}