import {
    library
} from "@fortawesome/fontawesome-svg-core";

import {
    //faSearch as faSearchSolid,
    //faTimesCircle as faTimesCircleSolid,
    //faChevronLeft as faChevronLeftSolid,
    //faChevronRight as faChevronRightSolid,
    //faBookmark as faBookmarkSolid,
    //faShareAlt as faShareAltSolid,
    //faExternalLinkAlt as faExternalLinkAltSolid,
    //faClock as faClockSolid,
    //faMapMarkerAlt as faMapMarkerAltSolid,
    //faInfoCircle as faInfoCircleSolid,

    //faTicketAlt as faTicketAltSolid,
    //faDollarSign as faDollarSignSolid,
    //faPhone as faPhoneSolid,
    //faPencilAlt as faPencilAltSolid,
    //faListUl as faListUlSolid,
    //faHeart as faHeartSolid,
    //faChevronCircleLeft as faChevronCircleLeftSolid,
    //faChevronCircleRight as faChevronCircleRightSolid,
    //faCloudUploadAlt as faCloudUploadAltSolid,
    //faCloudDownloadAlt as faCloudDownloadAltSolid,
    //faCloud as faCloudSolid,
    //faLongArrowAltUp as faLongArrowAltUpSolid,
    //faLongArrowAltDown as faLongArrowAltDownSolid,
    //faArrowCircleRight as faArrowCircleRightSolid,
    //faCheckSquare as faCheckSquareSolid,
    //faCheck as faCheckSolid,
    //faEllipsisV as faEllipsisVSolid,
    //faEdit as faEditSolid,
    //faCopy as faCopySolid,
    //faClipboardCheck as faClipboardCheckSolid,
    //faTrophy as faTrophySolid,
    //faMedal as faMedalSolid,
    //faCertificate as faCertificateSolid,
    //faBell as faBellSolid,
    //faUserSecret as faUserSecret,
    //faQuestionCircle as faQuestionCircleSolid,
    //faFont as faFontSolid,
    //faLock as faLockSolid,
    //faEnvelope as faEnvelopeSolid,
    //faChevronDown as faChevronDownSolid,
    //faArrowCircleLeft as faArrowCircleLeftSolid,
    //faSave as faSaveSolid,
    //faPlusSquare as faPlusSquareSolid,
    //faMinusSquare as faMinusSquareSolid,
    faEye as faEyeSolid,
    faEyeSlash as faEyeSlashSolid,
    faMinus as faMinusSolid,
    faTrash as faTrashSolid,
    faTrashAlt as faTrashAltSolid,
    faHdd as faHddSolid,
    faCaretDown as faCaretDownSolid,
    faCaretLeft as faCaretLeftSolid,
    faCaretRight as faCaretRightSolid,
    faServer as faServerSolid,
    faWarehouse as faWarehouseSolid,
    faFileInvoiceDollar as faFileInvoiceDollarSolid,
    faAddressBook as faAddressBookSolid,
    faHandshake as faHandShakeSolid,
    faTasks as faTasksSolid,
    faChartLine as faChartLineSolid,
    faCog as faCogSolid,
    faPlus as faPlusSolid,
    faFileCsv as faFileCsv,
    faCheckCircle as faCheckCircleSolid,
    faPlusCircle as faPlusCircleSolid,
    faMinusCircle as faMinusCircleSolid,
    faArrowLeft as faArrowLeftSolid,
    faArrowRight as faArrowRightSolid,
    faPrint as faPrintSolid,
    faSignOutAlt as faSignOutAltSolid,
    faKey as faKeySolid,
    faBars as faBarsSolid,
    faDirections as faDirectionsSolid,
} from "@fortawesome/free-solid-svg-icons";

/*import {

} from "@fortawesome/pro-solid-svg-icons";*/

import {
    faNewspaper as faNewspaperRegular,
    faCalendar as faCalendarRegular,
    faCalendarTimes as faCalendarTimesRegular,
    faHandshake as faHandshakeRegular,
    faSquare as faSquareRegular,
    faCircle as faCircleRegular,
    faBell as faBellRegular,
    faSave as faSaveRegular,

} from "@fortawesome/free-regular-svg-icons";

/*import {

} from "@fortawesome/pro-regular-svg-icons";*/

/*import {

} from "@fortawesome/pro-light-svg-icons";*/

/*import {

} from "@fortawesome/pro-duotone-svg-icons";*/

import {

} from "@fortawesome/free-brands-svg-icons";

library.add(
    //faSearchSolid,
    //faTimesCircleSolid,
    //faChevronLeftSolid,
    //faChevronRightSolid,
    //faBookmarkSolid,
    //faShareAltSolid,
    //faExternalLinkAltSolid,
    //faClockSolid,
    //faMapMarkerAltSolid,
    //faInfoCircleSolid,
    //faTicketAltSolid,
    //faDollarSignSolid,
    //faPhoneSolid,
    //faPencilAltSolid,
    //faListUlSolid,
    //faHeartSolid,
    //faChevronCircleLeftSolid,
    //faChevronCircleRightSolid,
    //faCloudUploadAltSolid,
    //faCloudDownloadAltSolid,
    //faCloudSolid,
    //faLongArrowAltUpSolid,
    //faLongArrowAltDownSolid,
    //faArrowCircleRightSolid,
    //faCheckSquareSolid,
    //faCheckSolid,
    //faEllipsisVSolid,
    //faEditSolid,
    //faCopySolid,
    //faClipboardCheckSolid,
    //faTrophySolid,
    //faMedalSolid,
    //faCertificateSolid,
    //faBellSolid,
    //faUserSecret,
    //faQuestionCircleSolid,
    //faFontSolid,
    //faLockSolid,
    //faEnvelopeSolid,
    //faChevronDownSolid,
    //faArrowCircleLeftSolid,
    //faSaveSolid,
    //faPlusSquareSolid,
    //faMinusSquareSolid,
    faMinusSolid,
    faTrashSolid,
    faTrashAltSolid,
    faHddSolid,
    faCaretDownSolid,
    faCaretLeftSolid,
    faCaretRightSolid,
    faServerSolid,
    faWarehouseSolid,
    faFileInvoiceDollarSolid,
    faAddressBookSolid,
    faHandShakeSolid,
    faTasksSolid,
    faChartLineSolid,
    faCogSolid,
    faPlusSolid,
    faFileCsv,
    faCheckCircleSolid,
    faPlusCircleSolid,
    faMinusCircleSolid,
    faArrowLeftSolid,
    faArrowRightSolid,
    faPrintSolid,
    faSignOutAltSolid,
    faKeySolid,
    faEyeSolid,
    faEyeSlashSolid,
    faBarsSolid,
    faDirectionsSolid,

    //faNewspaperRegular,
    //faCalendarRegular,
    //faCalendarTimesRegular,
    //faHandshakeRegular,
    //faSquareRegular,
    //faCircleRegular,
    //faBellRegular,
    //faSaveRegular,
);
