export const suppliers = [
    {
        _id: "198fa6f1-8204-46c3-a21c-039d2a3e4d5d",
        supplierNo: "SUP-198FA6F1",
        name: "Visual Suspect",
        type: "Company",
        address: "44 Wong Chuck Hang Road, Hong Kong Island, HK",
        contacts: [
            {
                name: "Jimmy Yeung",
                phone: "98206832",
                email: "jimmy.yeung@visualsuspect.co",
            }
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "150000",
        paymentTerms: "30% upfront, remaining amount due upon delivery of products, 15 days grace period, NET 30",
        handler: "4ed130ed-858c-4c65-8d7f-b08cbb964f77",
        notes: "Video studio, media content supplier",
    },
    {
        _id: "458d01af-c463-4daf-9843-44aec4617d3e",
        supplierNo: "SUP-458D01AF",
        name: "Sandbox ltd.",
        type: "Company",
        address: "10 Floor, Aberdeen Industrial Building, 236 Aberdeen Main Road, Aberdeen, Hong Kong",
        contacts: [
            {
                name: "Wayne Wong",
                phone: "25720011",
                email: "wayne.wong@sandbox.hk",
            }
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "150000",
        paymentTerms: "30% upfront, remaining amount due upon delivery of products, 15 days grace period, NET 30",
        handler: "4ed130ed-858c-4c65-8d7f-b08cbb964f77",
        notes: "Video studio, media content supplier",
    },
    {
        _id: "40d7f4c8-e39f-4ab9-ae09-97c878e5d2d6",
        supplierNo: "SUP-40D7F4C8",
        name: "90 Seconds",
        type: "Company",
        address: "2003, 20/F., Tower 5, China Hong Kong City, 33 Canton Road, Tsim Sha Tsui, Kowloon, Hong Kong",
        contacts: [
            {
                name: "Eunis Choi",
                phone: "94850937",
                email: "eunis.choi@90seconds.com.hk",
            }
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "100000",
        paymentTerms: "30% upfront, remaining amount due upon delivery of products, 15 days grace period, NET 30",
        handler: "4ed130ed-858c-4c65-8d7f-b08cbb964f77",
        notes: "Video studio, media content supplier",
    },
    {
        _id: "99119e65-2be6-4b30-b15c-299d76bebb48",
        supplierNo: "SUP-99119E65",
        name: "Yoga Room",
        type: "Company",
        address: "15/F, Xiu Ping Commercial Building, 104 Jervois Street, Sheung Wan, HK",
        contacts: [
            {
                name: "Catherine Leung",
                phone: "25448398",
                email: "cat.leung@yogaroomhk.com",
            }
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "100000",
        paymentTerms: "30% upfront, remaining amount due upon delivery of service, 15 days grace period, NET 30",
        handler: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        notes: "Yoga studio, yoga instructor supplier",
    },
    {
        _id: "224e190c-28ce-4f87-a79c-e977ef035f1f",
        supplierNo: "SUP-224E190C",
        name: "Eagle Focus",
        type: "Company",
        address: "FLAT/RM B 5/F GAYLORD COMMERCIAL BUILDING 114-118 LOCKHART ROAD, Hong Kong",
        contacts: [
            {
                name: "Angela Lam",
                phone: "63266796",
                email: "angela@eaglefocus.com.hk",
            }
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "150000",
        paymentTerms: "30% upfront, remaining amount due upon delivery of products, 15 days grace period, NET 30",
        handler: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        notes: "Event platform and staging suppliers",
    },
    {
        _id: "6e85889f-cdf9-4a92-9da5-770dbab2861e",
        supplierNo: "SUP-6E85889F",
        name: "E-Banner Hong Kong",
        type: "Company",
        address: "Shop CD5(A), G/F., Hang Fung Industrial Building, Phase 1, No, 2G Hok Yuen St, Hung Hom, Hong Kong",
        contacts: [
            {
                name: "Cathy Lee",
                phone: "23447776",
                email: "cathy@e-banner.com",
            }
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "100000",
        paymentTerms: "pay in full upfront for amount less than 10000 HKD, 50% upfront for amount greater than 10000 HKD, "  +
            "remaining amount due upon product delivery, NET 30",
        handler: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        notes: "Display banner and booth rental supplier",
    },
    {
        _id: "8e9709be-fb2d-458c-b891-e01d63057dc4",
        supplierNo: "SUP-8E9709BE",
        name: "Fiesta Hong Kong",
        type: "Company",
        address: "2/F, 8B On Kui Street, On Lok Tsuen. Fanling, N.T., Hong Kong",
        contacts: [
            {
                name: "David Fong",
                phone: "26779837",
                email: "david.fong@fiesta.hk",
            }
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "150000",
        paymentTerms: "30% upfront, remaining amount due upon delivery of products, 15 days grace period, NET 30",
        handler: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        notes: "Major event decoration, lighting and furniture rental supplier",
    },
    {
        _id: "11139cb7-2ffa-4342-8d8f-c008a9cb433f",
        supplierNo: "SUP-11139CB7",
        name: "SENS Studio Ltd",
        type: "Company",
        address: "Flat 02, 12/F, Westlands Centre, 20 Westlands Road, Tai Koo, Hong Kong.",
        contacts: [
            {
                name: "Betty Ng",
                phone: "31880600",
                email: "betty@sensstudio.com.hk",
            }
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "30000",
        paymentTerms: "10% upfront, remaining amount paid per milestone and progress of work, NET 30",
        handler: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        notes: "Photographer and photo studio service supplier",
    },
    {
        _id: "a769a2ed-cd4e-4166-82aa-687947618bb8",
        supplierNo: "SUP-A769A2ED",
        name: "Guardforce Limited",
        type: "Company",
        address: "1/F, Guardforce Centre, 3 Hok Yuen Street East, Hung Hom, Kowloon, Hong Kong",
        contacts: [
            {
                name: "Tony Pang",
                phone: "27657654",
                email: "tonny@guardforce.com.hk",
            }
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "150000",
        paymentTerms: "30% upfront, remaining amount due upon delivery of service, 15 days grace period, NET 30",
        handler: "4ed130ed-858c-4c65-8d7f-b08cbb964f77",
        notes: "Security arrangement supplier for events",
    },
    {
        _id: "fe674c7d-a786-4056-947d-d1ef75932caa",
        supplierNo: "SUP-FE674C7D",
        name: "Pret-a-Dress",
        type: "Company",
        address: "25/F, Unit A, Car Po Commercial Building, 18 Lyndhurst Terrace, Central, Hong Kong",
        contacts: [
            {
                name: "Polly Poon",
                phone: "22178330",
                email: "polly.poon@pret-a-dress.com",
            }
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "100000",
        paymentTerms: "30% upfront, remaining amount due upon delivery of service, 15 days grace period, NET 30",
        handler: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        notes: "Clothing and Jewellery rental supplier",
    },
    {
        _id: "c754fba2-2c12-41a5-bce4-36f65a3aaeaa",
        supplierNo: "SUP-C754FBA2",
        name: "Tai Yan Printing Co.",
        type: "Company",
        address: "2/F H, Kwun Tong Industrial Centre Phase 3, 448-458 Kwun Tong Rd, Kwun Tong, Hong Kong",
        contacts: [
            {
                name: "Tommy Choi",
                phone: "23729338",
                email: "taiyan889@yahoo.com.hk",
            }
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "10000",
        paymentTerms: "paid in full upfront, NET 30",
        handler: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        notes: "Printing materials, bags and packaging product supplier",
    },
    {
        _id: "795990b8-5011-4e24-aec9-57759e363cde",
        supplierNo: "SUP-795990B8",
        name: "Be Tabula Rasa",
        type: "Company",
        address: "93 Lai Chi Kok Road Prince Edward, Hong Kong",
        contacts: [
            {
                name: "Zoe Pong",
                phone: "90102761",
                email: "Zoe199213568@gmail.com",
            }
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "10000",
        paymentTerms: "pay in full upfront, NET 30",
        handler: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        notes: "Florist supplier for event",
    },
]