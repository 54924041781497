<template>
    <div class="sidebar position-absolute h-100 text-white d-flex flex-column" v-bind:class="{'hide': navBarHidden}">
        <div class="container-logo position-relative w-100 text-center d-flex justify-content-center align-items-center flex-shrink-0">
            <h4 class="logo font-lobster w-100 h-auto p-4 m-0 flex-shrink-0">
                CINQUE
            </h4>
            <div class="hide-button position-absolute d-flex justify-content-center align-items-center"
                 v-on:click="$emit('hide-nav-bar')">
                <font-awesome-icon class="hide-icon position-relative flex-shrink-0"
                                   v-bind:icon="['fas', 'arrow-left']"></font-awesome-icon>
            </div>
        </div>
        <div class="panels position-relative w-100 py-3 flex-grow-1 flex-shrink-0">
            <div class="panel-titles position-relative w-100 h-auto"
                 v-for="panel in panels"
                 v-bind:key="panel.title">
                <font-awesome-icon class="collapsible-icon position-absolute"
                                   v-bind:icon="['fas', 'caret-down']"
                                   v-bind:class="{'flip': expandedTitles.includes(panel.title)}"></font-awesome-icon>
                <h4 class="panel-title position-relative w-100 p-3 m-0 d-flex align-items-center" v-on:click="collapsible(panel.title)">
                    <span>{{ panel.title }}</span>
                </h4>
                <div class="sub-panel-titles position-relative w-100"
                     v-bind:class="{'expand': expandedTitles.includes(panel.title)}">
                    <router-link class="sub-panel-title position-relative w-100 text-decoration-none text-white d-flex align-items-center pl-4 pr-2 py-2"
                        v-for="subPanel in panel.subPanels"
                        v-bind:key="subPanel.title"
                        v-bind:to="subPanel.path">
                        <h5 class="font-weight-normal mb-0">
                            {{ subPanel.title }}
                        </h5>
                    </router-link>
                </div>
            </div>
            <h4 class="logout position-relative w-100 p-3 m-0 d-flex align-items-center" v-on:click="$emit('logout')">
                <span>Logout</span>
            </h4>
        </div>
        <div class="user-profile w-100 text-center d-flex justify-content-between"
             v-on:click="$emit('navigate', '/user')">
            <img class="avatar position-relative"
                 v-bind:src="user.avatar"
                 v-bind:alt="user.name">
            <div class="py-2 px-3">
                <h5 class="flex-grow-1 name text-right text-truncate mb-0">
                    {{ user.name }}
                </h5>
                <p class="text-right font-weight-light mb-0">Administrator</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Sidebar",
    props: {
        navBarHidden: {
            type: Boolean,
            required: true
        },
        user: {
            type: Object,
            required: true
        },
        panels: {
            type: Array,
            required: true
        }
    },
    
    data() {
        return {
            expandedTitles: [],
        }
    },
    
    methods: {
        collapsible(title) {
            const index = this.expandedTitles.indexOf(title)
            if (index === -1) {
                this.expandedTitles.push(title);
            } else {
                this.expandedTitles.splice(index, 1);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.sidebar {
    top: 0;
    left: 0;
    width: 310px;
    background-color: rgba(57, 83, 138, .85);
    backdrop-filter: blur(5px);
    user-select: none;
    z-index: 999;
    transition: all .5s ease;
    
    
    &.hide {
        transform: translateX(-100%);
    }
    
    .container-logo {
        height: 60px;
        
        .hide-button {
            top: 10px;
            left: 10px;
            width: 40px;
            height: 40px;
            cursor: pointer;
            border-radius: 100px;
            transition: all .3s ease;
            
            &:hover {
                background-color: rgba(255, 255, 255, .25);
            }
            
            .hide-icon {
                width: 25px;
                height: 25px;
            }
        }
    }
    
    .panels {
        height: calc(100% - 60px - 80px);
        overflow-y: auto;
        
        &::-webkit-scrollbar {
            width: 6px;
        }
        
        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
        
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: $color-grey-button;
        }
        
        .panel-titles {
            .collapsible-icon {
                top: 22px;
                right: 10px;
                width: 20px;
                height: 20px;
                transition: all .2s ease;
                
                &.flip {
                    transform: rotate(180deg);
                }
            }
            
            .panel-title {
                height: 60px;
                cursor: pointer;
                transition: all .2s ease;
                
                &:hover {
                    background-color: rgba(0, 0, 0, .3);
                }
            }
            
            .sub-panel-titles {
                max-height: 0;
                overflow-y: hidden;
                transition: all .25s ease-in-out;
                
                &.expand {
                    max-height: 240px;
                }
                
                .sub-panel-title {
                    transition: all .2s ease;
                    
                    &:hover {
                        background-color: rgba(0, 0, 0, .3);
                    }
                }
            }
        }
        
        .logout {
            height: 60px;
            cursor: pointer;
            transition: all .2s ease;
            
            &:hover {
                background-color: rgba(0, 0, 0, .3);
            }
        }
    }
    
    .user-profile {
        background-color: $color-theme-text-icon;
	    cursor: pointer;
        z-index: 1;
        
        .avatar {
            width: 60px;
            object-fit: cover;
        }
    }
}
</style>
