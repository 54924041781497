export const tasks = [
    {
        _id: "e92de7d3-52d3-4fa6-b1f4-587d09b065f3",
        taskNo: "TAS-08F29875",
        // open, closed
        status: "closed",
        // lead, opportunity, sales, campaign
        stage: "lead",
        client: "1e6f3091-6acd-4999-a0d8-b4ac29cefdbd",
        quotationRequests: [
            "4e7ed7f0-fbac-42d9-b30b-33769f83cc5f",
            "ae441637-05a8-4bfc-a282-5be96771b041",
            "f21a501b-3d0d-4dc7-89e6-0163e1877282",
        ],
        supplierQuotations: [
            "b6567dd7-d34f-4262-92b8-b1a4f60aafe6",
            "b18cae3f-de54-45d3-8311-b5684417b08c",
            "1612fbd3-5b80-4797-9e1d-58896f3653b2",
        ],
        purchaseOrders: [

        ],
        salesQuotations: [
            "ca714e08-bd77-4d8a-b545-09e18d262f13",
        ],
        salesOrders: [

        ],
        campaigns: [

        ],
        dueAt: "2020-11-12T13:00:00.000+00:00",
        createdAt: "2020-10-12T05:12:12.868+00:00",
        updatedAt: "2020-11-13T08:22:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        handler: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "",
    },
    {
        _id: "08f29877-192b-4e82-b26c-859841f1ca6c",
        taskNo: "TAS-08F29877",
        // open, closed
        status: "open",
        // lead, opportunity, sales, campaign
        stage: "campaign",
        client: "1e6f3091-6acd-4999-a0d8-b4ac29cefdbd",
        quotationRequests: [
            "4e7ed7f0-fbac-42d9-b30b-33769f83cc5f",
            "ae441637-05a8-4bfc-a282-5be96771b041",
            "f21a501b-3d0d-4dc7-89e6-0163e1877282",
        ],
        supplierQuotations: [
            "b6567dd7-d34f-4262-92b8-b1a4f60aafe6",
            "b18cae3f-de54-45d3-8311-b5684417b08c",
            "1612fbd3-5b80-4797-9e1d-58896f3653b2",
        ],
        purchaseOrders: [
            "9a745e0d-81a7-4b87-ac74-3da9351c272b",
            "51b30aeb-6153-4ff6-8c7f-98c7ab4e3640",
        ],
        salesQuotations: [
            "ca714e08-bd77-4d8a-b545-09e18d262f13",
        ],
        salesOrders: [
            "3dab44f7-d755-41c7-b73b-c1c3ab3fe43b",
        ],
        campaigns: [
            "bbefe70a-c6a0-4ae1-8a00-ffcb25bbe391",
            "35779108-e1ff-471b-aa07-1283c8d26aa0",
            "11620348-7b73-4217-93b3-8fec70ac1fdd",
        ],
        dueAt: "2021-01-03T13:00:00.000+00:00",
        createdAt: "2020-10-12T05:12:12.868+00:00",
        updatedAt: "2020-12-21T08:22:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        handler: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "",
    },
    {
        _id: "0c9bf8c8-75f2-46be-937a-f1b3c87c00c4",
        taskNo: "TAS-0C9BF8C8",
        // open, closed
        status: "open",
        // lead, opportunity, sales, campaign
        stage: "campaign",
        client: "2e214095-34df-4c53-bacb-57f159ceec6d",
        quotationRequests: [
            "8aaaa9ce-e081-4d0e-aa93-d70e136395ee",
        ],
        supplierQuotations: [
            "be6aa4b1-b95c-4cc8-9cb4-84161d83248e",
        ],
        purchaseOrders: [
            "9400884f-3c6a-4701-abac-295fc7bb642c",
        ],
        salesQuotations: [
            "e8d98a1b-fa0e-4a35-a2f0-df2e343c5ae9",
        ],
        salesOrders: [
            "125ed4d4-ae88-4d25-865d-edb99f17b6fc",
        ],
        campaigns: [
            "d3cabfa0-0c56-4040-bdc8-feb29ebe28b0",
            "afc2e22c-67b0-46d1-9c35-6e8f9d3f3bb9",
        ],
        dueAt: "2021-01-31T13:00:00.000+00:00",
        createdAt: "2020-10-23T02:56:12.868+00:00",
        updatedAt: "2020-12-21T06:18:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        handler: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "",
    },
    {
        _id: "a5cf5dcf-773d-4de9-a362-31ab13817d3d",
        taskNo: "TAS-A5CF5DCF",
        // open, closed
        status: "open",
        // lead, opportunity, sales, campaign
        stage: "campaign",
        client: "dca1a4e0-7828-4782-9b7c-d6030a775a68",
        quotationRequests: [
            "54da2f61-524f-4ad8-8b08-c97ca6cb501c",
        ],
        supplierQuotations: [
            "8eb041a6-d444-40a3-925e-8c5a682b8016",
        ],
        purchaseOrders: [
            "e9702274-8359-496a-9739-29fd3861038b",
        ],
        salesQuotations: [
            "1d699e35-02e6-476f-aad9-bd0a6cc36e16",
        ],
        salesOrders: [
            "9cc82fd0-05c4-4d5a-a0b5-2271ec6cab57",
        ],
        campaigns: [
            "a0a36bf0-a0de-4ddf-8c4b-a52e191ec42d",
            "e0f7e576-2926-4203-adeb-306f055c4809",
            "269b55c7-e65b-4718-9d72-472d28d1ca20",
        ],
        dueAt: "2021-01-02T01:00:00.000+00:00",
        createdAt: "2020-10-13T04:38:12.868+00:00",
        updatedAt: "2020-12-21T04:55:12.868+00:00",
        createdBy: "4ed130ed-858c-4c65-8d7f-b08cbb964f77",
        updatedBy: "4ed130ed-858c-4c65-8d7f-b08cbb964f77",
        handler: "4ed130ed-858c-4c65-8d7f-b08cbb964f77",
        notes: "",
    },
    {
        _id: "a63dd958-5070-4786-a9c7-5de269c71ff2",
        taskNo: "TAS-A63DD958",
        // open, closed
        status: "open",
        // lead, opportunity, sales, campaign
        stage: "campaign",
        client: "9383fc7e-7684-443f-bde9-36476db86efe",
        quotationRequests: [
            "f22214fd-e7e0-499f-b5b4-063f755e0ca7",
            "9d755922-c07e-4ca4-a33f-e3ea6a90d2a7",
            "fb4effea-fc5f-4e3c-a379-2b466acad2e5",
            "19d62084-7aad-4315-b036-cec556b3c8b8",
            "af64ccf5-a28b-4d4c-9f03-3f9b6a000656",
        ],
        supplierQuotations: [
            "7444d4ff-fd9d-45b6-82b7-41688e962356",
            "7371e44d-d65a-4b92-8327-5b32d6e02290",
            "a8212726-d011-4936-98f0-befc816866ff",
            "0ea302ec-db29-4322-8c1e-01b6cd4d78d7",
            "5402d083-0b9e-429f-8f50-e6688e981296",
        ],
        purchaseOrders: [
            "a6f49d1c-a80c-4e7b-9332-076ace428ec5",
            "c4ce5550-5858-4be1-a259-7fee9775a55b",
            "a5c9e7c6-55a2-4e39-8049-9b6ecc6a4656",
            "c5d6e4c5-c3e6-405e-993e-aa57dbab72c9",
            "0fb80a7a-b07c-41fd-bd3f-27256dcb06b6",
        ],
        salesQuotations: [
            "7606ddc3-9099-425c-b83f-e34b9a7fb1bd",
        ],
        salesOrders: [
            "0c3eb67d-8797-4024-8601-a195ebe2dc5a",
        ],
        campaigns: [
            "4257b0dd-32fb-43d6-aa95-6cda5e68a98e",
            "11e815c1-8176-4ef9-ad67-587fe6de7e0e",
        ],
        dueAt: "2021-01-04T13:00:00.000+00:00",
        createdAt: "2020-10-22T05:46:12.868+00:00",
        updatedAt: "2020-12-23T02:17:12.000+00:00",
        createdBy: "4ed130ed-858c-4c65-8d7f-b08cbb964f77",
        updatedBy: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        handler: "4ed130ed-858c-4c65-8d7f-b08cbb964f77",
        notes: "",
    },
    {
        _id: "ab35239f-4bff-40d7-860d-4e17b68bfff1",
        taskNo: "TAS-AB35239F",
        // open, closed
        status: "open",
        // lead, opportunity, sales, campaign
        stage: "campaign",
        client: "45b6a3c1-c8f9-423f-95d8-133574f5538b",
        quotationRequests: [
            "ad731bb5-4d7a-44e6-8165-1cd9b70fb2c8",
            "e401fd78-231c-41d6-b2d3-7e5c8bb0cf15",
            "2a3f7ec1-9210-416a-ae09-76e7ce759af4",
        ],
        supplierQuotations: [
            "67be3327-9225-4060-90b6-6d91e85ecafa",
            "d9f1f048-bb67-4700-b2ab-b27d3834286d",
            "9e6b9375-b401-496a-b9e9-a160f2ab4d24",
        ],
        purchaseOrders: [
            "9acc6aba-a5dc-45de-94a8-25a1c3c0be77",
        ],
        salesQuotations: [
            "353a82cf-c74c-42e8-a9c6-fbb0a9e0f8dc",
        ],
        salesOrders: [
            "e4fd32e2-fa8f-472d-862a-b88a691c96d5",
        ],
        campaigns: [
            "80bfc017-974c-43ce-aef5-a9b7ee86710f",
            "9d614d64-b48a-4fa7-bea3-5a250f7c8c22",
            "e03f672c-7544-47ed-80a4-5df85e599e74",
        ],
        dueAt: "2021-01-03T13:00:00.000+00:00",
        createdAt: "2020-11-24T01:56:12.868+00:00",
        updatedAt: "2020-12-20T02:06:12.000+00:00",
        createdBy: "4ed130ed-858c-4c65-8d7f-b08cbb964f77",
        updatedBy: "4ed130ed-858c-4c65-8d7f-b08cbb964f77",
        handler: "4ed130ed-858c-4c65-8d7f-b08cbb964f77",
        notes: "",
    },
    {
        _id: "1562bba5-4b5f-4713-8529-7fd40f77ab9d",
        taskNo: "TAS-1562BBA5",
        // open, closed
        status: "open",
        // lead, opportunity, sales, campaign
        stage: "campaign",
        client: "d0ed9856-1f26-4b34-be6a-4bf52f2603a6",
        quotationRequests: [
            "832a78b8-d1e3-4084-aa9c-dfd31329a289",
            "d66c3ab8-d17b-433c-9192-0ddcc88bed11",
            "aea4adbb-0699-463c-8e85-d83d1a784098",
        ],
        supplierQuotations: [
            "2d05f899-75ec-477b-a6eb-9202fbc014ea",
            "138a0688-00aa-433b-a7be-2b12266a90ce",
            "81a63446-5d53-4ab6-822c-4623fb38a8fc",
        ],
        purchaseOrders: [
            "56cde022-71c8-44e8-a7b6-503b241bd0b7",
        ],
        salesQuotations: [
            "7a0607b2-f330-4246-ae2f-8859c743977d",
        ],
        salesOrders: [
            "5cb834f0-76cb-4219-af74-e8c4c9ca1b66",
        ],
        campaigns: [
            "7c6fbf5c-beee-4749-ad2b-0db1dc96d3ff",
            "41516cf7-db40-4585-958f-909947e1922c",
        ],
        dueAt: "2021-01-03T13:00:00.000+00:00",
        createdAt: "2020-11-16T08:54:12.868+00:00",
        updatedAt: "2020-12-21T01:13:00.000+00:00",
        createdBy: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        updatedBy: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        handler: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        notes: "",
    },
    {
        _id: "720fdcd6-98e3-43b5-a35f-805a96ea5148",
        taskNo: "TAS-720FDCD6",
        // open, closed
        status: "open",
        // lead, opportunity, sales, campaign
        stage: "sales",
        client: "23b1562a-8da7-4e7e-a921-bf1c940b67b9",
        quotationRequests: [
            "278a2360-3d65-43fa-908e-62d9a8929f40",
            "7f81c140-1cf9-418f-b9cb-e7c951da2027",
        ],
        supplierQuotations: [
            "3a7a6529-e3be-4739-8f47-fea785b92947",
            "b800de80-7f51-45da-92bb-1e1279e8404d",
        ],
        purchaseOrders: [],
        salesQuotations: [
            "2f53cc82-09ac-411f-90fe-99dff9bb48c9",
        ],
        salesOrders: [],
        campaigns: [],
        dueAt: "2021-01-03T07:29:12.868+00:00",
        createdAt: "2020-12-03T07:29:12.868+00:00",
        updatedAt: "2020-12-21T09:03:12.868+00:00",
        createdBy: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        handler: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        notes: "",
    },
    {
        _id: "dd76cb50-4973-496f-9825-a92e396f0b59",
        taskNo: "TAS-DD76CB50",
        // open, closed
        status: "closed",
        // lead, opportunity, sales, campaign
        stage: "sales",
        client: "6d4dd062-665a-4069-acf3-ad9c584913ac",
        quotationRequests: [
            "c207ffe3-4e02-453d-be54-d03ca24e6f20",
            "70d1b971-1b39-4728-ad05-770a6d2f1784",
            "bffb5f1b-1904-41ce-a3cf-9fc9a115e5ee",
            "4159b0eb-6c14-4d5a-8b5e-5c0d90395686",
        ],
        supplierQuotations: [
            "4fa0925e-ca5c-423d-a4ec-fb5a881cb05e",
            "68db860c-1b31-4129-a373-9d8d11d18fa3",
            "776ce7e6-cee9-440b-81ff-da23c95be3a6",
            "6419e5a6-fa5b-4ae0-87d0-9f90445361fa",
        ],
        purchaseOrders: [],
        salesQuotations: [
            "cd48702f-ffb5-49ef-a853-66456b3b595c",
        ],
        salesOrders: [],
        campaigns: [],
        dueAt: "2020-10-16T01:00:00.000+00:00",
        createdAt: "2020-08-26T02:36:12.868+00:00",
        updatedAt: "2020-09-14T08:08:12.868+00:00",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        handler: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        notes: "",
    },
]
