export const salesPartners = [
    {
        _id: "145bc5e6-7d14-4291-b954-17583f131d72",
        partnerNo: "SPR-145BC5E6",
        name: "Buzzfever",
        type: "Company",
        address: "Rm. 906, 9/F, The Galaxy, 313 Castle Peak Road, Kwai Chung, Kowloon, Hong Kong",
        contacts: [
            {
                name: "Brian Lui",
                phone: "39569683",
                email: "brian.lui@buzzfever.hk",
            }
        ],
        handler: "a0ec763e-5084-4e1f-af64-8249c3dde67b",
        notes: "",
    },
    {
        _id: "511bbc39-142d-4045-b4bf-21e450b269a2",
        partnerNo: "SPR-511BBC39",
        name: "Rabbit Studio",
        type: "Company",
        address: "Unit 511B, 5/F, InnoCentre, 72 Tat Chee Avenue, Kowloon Tong, Kowloon, Hong Kong",
        contacts: [
            {
                name: "Anne Kwan",
                phone: "70722920",
                email: "anne.kwan@rabbitstudio.net",
            }
        ],
        handler: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        notes: "",
    },
    {
        _id: "20a9ac9f-f370-4a41-b1ed-6feefefc73c5",
        partnerNo: "SPR-20A9AC9F",
        name: "Digital Business Lab",
        type: "Company",
        address: "1501, Pacific Plaza, 410 Des Voeux Road West, Sai Wan, Hong Kong",
        contacts: [
            {
                name: "Rachael Wong",
                phone: "81203518",
                email: "rachael.wong@db-lab.com",
            }
        ],
        handler: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        notes: "",
    },
]