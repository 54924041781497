export const documents = [
    {
        _id: "9b07895b-845a-4426-a323-a4996db874dc",
        documentNo: "DOC-9B07895B",
        name: "Customer Feedback Template",
        fileName: "feedback_cus.jpg",
        // image, video, text, other
        type: "image",
        url: "https://images.unsplash.com/photo-1600132806608-231446b2e7af?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1867&q=80",
        descriptions: "template for feedback survey form for COS Central",
        createdAt: "2021-01-06T06:15:11.000+00:00",
        updatedAt: "2021-01-06T06:15:11.000+00:00",
        createdBy: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        updatedBy: "279fcea9-927f-48a2-86ca-bef66bc61fba",
    }
]