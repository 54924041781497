export const clients = [
    {
        _id: "1e6f3091-6acd-4999-a0d8-b4ac29cefdbd",
        clientNo: "CUS-1E6F3091",
        name: "MUJI Harbour City",
        type: "Company",
        address: "Shop 415, Level 4, Ocean Centre, Harbour City, Tsim Sha Tsui, Kowloon, Hong Kong",
        contacts: [
            {
                name: "Eliza Choi",
                phone: "39713101",
                email: "eliza.choi@muji.com.hk",
            },
            {
                name: "Danielle Chan",
                phone: "95687341",
                email: "danielle.chan@muji.com.hk",
            },
            {
                name: "Jerry Wong",
                phone: "33352378",
                email: "jerry.wong@muji.com.hk",
            },
        ],
        currency: "JPY",
        exchangeRate: "0.075",
        creditLimit: "150000.00",
        handler: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        paymentTerms: "30% deposit, remaining amount due upon campaign completion, NET 15",
        notes: ""
    },
    {
        _id: "2e214095-34df-4c53-bacb-57f159ceec6d",
        clientNo: "CUS-2E214095",
        name: "IKEA Kowloon Bay",
        type: "Company",
        address: "38 Wang Chiu Rd, Kowloon Bay, Hong Kong",
        contacts: [
            {
                name: "Erika Leung",
                phone: "90652937",
                email: "erika.leung@ikea.com.hk",
            },
            {
                name: "Frankie Yiu",
                phone: "23895234",
                email: "frankie.yiu@ikea.com.hk",
            },
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "150000.00",
        handler: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        paymentTerms: "30% deposit, remaining amount due upon campaign completion, NET 15",
        notes: ""
    },
    {
        _id: "92e70d28-5642-4405-9403-9781a01375b5",
        clientNo: "CUS-92E70D28",
        name: "IKEA Tsuen Wan",
        type: "Company",
        address: "301 - 307 and 315, 388 Castle Peak Rd (Tsuen Wan), Tsuen Wan, Hong Kong",
        contacts: [
            {
                name: "Erika Leung",
                phone: "90652937",
                email: "frankie.yiu@ikea.com.hk",
            },
            {
                name: "Carmen Tseng",
                phone: "31250888",
                email: "carmen.tseng@ikea.com.hk",
            },
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "150000.00",
        handler: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        paymentTerms: "30% deposit, remaining amount due upon campaign completion, NET 15",
        notes: "",
    },
    {
        _id: "058c92c5-3aaa-4590-b694-8e23cfc4f226",
        clientNo: "CUS-058C92C5",
        name: "COS Festival Walk",
        type: "Company",
        address: "Shop LG1-36, Festival Walk, 80 Tat Chee Ave,, Hong Kong",
        contacts: [
            {
                name: "Ronald So",
                phone: "69682499",
                email: "ronald.so@coscorp.com.hk",
            }
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "100000.00",
        handler: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        paymentTerms: "30% deposit, remaining amount due upon campaign completion, NET 15",
        notes: "",
    },
    {
        _id: "9191eff6-0638-4a4b-a973-47a430823247",
        clientNo: "CUS-9191EFF6",
        name: "Bossini Hong Kong",
        type: "Company",
        address: "Shop No. 6-8, 10, G/F., Aberdeen Centre (Site 3), HK Aberdeen, Hong Kong",
        contacts: [
            {
                name: "Emmie Lai",
                phone: "25527731",
                email: "emmie.lai@bossini.com.hk",
            }
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "100000.00",
        handler: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        paymentTerms: "30% deposit, remaining amount due upon campaign completion, NET 15",
        notes: "",
    },
    {
        _id: "23b1562a-8da7-4e7e-a921-bf1c940b67b9",
        clientNo: "CUS-23B1562A",
        name: "HK Liquor Store",
        type: "Company",
        address: "G/F, 6 Yik Yam St, Happy Valley, Hong Kong",
        contacts: [
            {
                name: "Sam Chow",
                phone: "26811119",
                email: "sam.chow@liquorstore.com.hk",
            }
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "50000.00",
        handler: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        paymentTerms: "30% deposit, remaining amount due upon campaign completion, NET 15",
        notes: "",
    },
    {
        _id: "dca1a4e0-7828-4782-9b7c-d6030a775a68",
        clientNo: "CUS-DCA1A4E0",
        name: "Adidas Outlet Store",
        type: "Company",
        address: "Kaiser Estate Phase 2, Man, Lok Street, Hok Yuen, Hong Kong",
        contacts: [
            {
                name: "Tina Lung",
                phone: "27932791",
                email: "tina.lung@adidas.com.hk",
            }
        ],
        currency: "USD",
        exchangeRate: "7.75",
        creditLimit: "150000.00",
        handler: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        paymentTerms: "30% deposit, remaining amount due upon campaign completion, NET 15",
        notes: ""
    },
    {
        _id: "fddc98ab-f172-4fef-99be-1220aa295445",
        clientNo: "CUS-FDDC98AB",
        name: "Stussy Hong Kong",
        type: "Company",
        address: "9 Pak Sha Rd, Causeway Bay, Hong Kong",
        contacts: [
            {
                name: "Kelly Chan",
                phone: "36222456",
                email: "kelly.chan@stussy.com.hk",
            },
            {
                name: "Brian Wong",
                phone: "51159688",
                email: "brian.wong@stussy.com.hk",
            },
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "50000.00",
        handler: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        paymentTerms: "30% deposit, remaining amount due upon campaign completion, NET 15",
        notes: "",
    },
    {
        _id: "6d4dd062-665a-4069-acf3-ad9c584913ac",
        clientNo: "CUS-6D4DD062",
        name: "Apple Causeway Bay",
        type: "Company",
        address: "Hysan Place. 500 Hennessy Road. Hong Kong, Causeway Bay, Hong Kong",
        contacts: [
            {
                name: "Jasmin Wong",
                phone: "39793100",
                email: "jasmin.wong@apple.com.hk",
            },
            {
                name: "Katherine Lee",
                phone: "37962767",
                email: "katherine.lee@apple.com.hk",
            },
            {
                name: "Ashley Chiu",
                phone: "28961901",
                email: "ashley.chiu@apple.com.hk",
            },
        ],
        currency: "USD",
        exchangeRate: "7.75",
        creditLimit: "200000.00",
        handler: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        paymentTerms: "30% deposit, remaining amount due upon campaign completion, NET 15",
        notes: "",
    },
    {
        _id: "a965751c-73ee-435a-adcb-fc06a7ae7395",
        clientNo: "CUS-A965751C",
        name: "Popcorn",
        type: "Company",
        address: "Hong Kong Island Shop 409, 4/F, Hysan Place, 500 Hennessy Rd, Causeway Bay, Hong Kong",
        contacts: [
            {
                name: "Bonnie Lai",
                phone: "35470598",
                email: "bonnie.lai@popcornltd.com.hk",
            }
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "50000.00",
        handler: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        paymentTerms: "30% deposit, remaining amount due upon campaign completion, NET 15",
        notes: "",
    },
    {
        _id: "1febe6c9-cf65-44eb-bedb-7cfc655e2dbc",
        clientNo: "CUS-1FEBE6C9",
        name: "Marks & Spencer Shatin",
        type: "Company",
        address: "Shop 366, Level 3, New Town Plaza Phase 1, 18 Sha Tin Centre St, Sha Tin, Hong Kong",
        contacts: [
            {
                name: "Lili Kwan",
                phone: "29294331",
                email: "lili.kwan@mscorp.com.hk",
            },
            {
                name: "Karen Lau",
                phone: "62072085",
                email: "karen.lau@mscorp.com.hk",
            },
            {
                name: "Polly Ng",
                phone: "52919809",
                email: "polly.ng@mscorp.com.hk",
            }
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "150000.00",
        handler: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        paymentTerms: "30% deposit, remaining amount due upon campaign completion, NET 15",
        notes: "",
    },
    {
        _id: "9383fc7e-7684-443f-bde9-36476db86efe",
        clientNo: "CUS-9383FC7E",
        name: "Catalog",
        type: "Company",
        address: "Shop 32B, Upper Ground Floor, China Hong Kong City, 33 Canton Road,, Tsim Sha Tsui, Hong Kong",
        contacts: [
            {
                name: "Jasmin Leung",
                phone: "23020631",
                email: "jasmin.leung@catalogltd.com.hk",
            }
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "50000.00",
        handler: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        paymentTerms: "30% deposit, remaining amount due upon campaign completion, NET 15",
        notes: ""
    },
    {
        _id: "96e9d637-7d6c-47e0-b39e-5efb2f20270e",
        clientNo: "CUS-96E9D637",
        name: "Lane Crawford",
        type: "Company",
        address: "1 Matheson St, Causeway Bay, Hong Kong",
        contacts: [
            {
                name: "Aleena Cheung",
                phone: "21182288",
                email: "aleena.cheung@lanecrawford.com.hk",
            }
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "50000.00",
        handler: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        paymentTerms: "30% deposit, remaining amount due upon campaign completion, NET 15",
        notes: "",
    },
    {
        _id: "4d9e4847-6302-4b79-a8b7-b6e5d892a55a",
        clientNo: "CUS-4D9E4847",
        name: "Moleskine Store",
        type: "Company",
        address: "K11 Art Mall, Tsim Sha Tsui, Hong Kong, Shop 112, Level 1 - K11 Art Mall - 18 Hanoi Street, Tsim Sha Tsui, Hong Kong",
        contacts: [
            {
                name: "Betty Loo",
                phone: "35847877",
                email: "betty.loo@moleskine.com.hk",
            }
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "50000.00",
        handler: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        paymentTerms: "30% deposit, remaining amount due upon campaign completion, NET 15",
        notes: "",
    },
    {
        _id: "45b6a3c1-c8f9-423f-95d8-133574f5538b",
        clientNo: "CUS-45B6A3C1",
        name: "COS Central",
        type: "Company",
        address: "74 Queen’s Road Central, Hong Kong",
        contacts: [
            {
                name: "Ronald So",
                phone: "69682499",
                email: "ronald.so@coscorp.com.hk",
            }
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "100000.00",
        handler: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        paymentTerms: "30% deposit, remaining amount due upon campaign completion, NET 15",
        notes: ""
    },
    {
        _id: "d0ed9856-1f26-4b34-be6a-4bf52f2603a6",
        clientNo: "CUS-D0ED9856",
        name: "_Julius Hong Kong",
        type: "Company",
        address: "Kingston Building Block B, 2-4 Kingston St, Causeway Bay, Hong Kong",
        contacts: [
            {
                name: "Emelia Chan",
                phone: "28816102",
                email: "Emelia.chan@julius.com.hk",
            }
        ],
        currency: "HKD",
        exchangeRate: "1",
        creditLimit: "50000.00",
        handler: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        paymentTerms: "30% deposit, remaining amount due upon campaign completion, NET 15",
        notes: ""
    },
]