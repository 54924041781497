export const salesQuotations = [
    {
        _id: "ca714e08-bd77-4d8a-b545-09e18d262f13",
        quoteNo: "QUO-CA714E08",
        client: "1e6f3091-6acd-4999-a0d8-b4ac29cefdbd",
        items: [
            {
                name: "Daily front door booth promotion during Christmas and New Year holidays",
                quantity: "14",
                unitPrice: "15200.00",
            },
            {
                name: "TV advertisement package",
                quantity: "1",
                unitPrice: "95500.00",
            },
            {
                name: "Social media advertisement package",
                quantity: "1",
                unitPrice: "38200.00",
            },
        ],
        currency: "HKD",
        serviceFee: "25000.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "17500.00",
        totalAmount: "156400.00",
        paymentTerms: "30% upfront, remaining amount due upon campaign completion, NET 15",
        issuedAt: "2020-11-02T04:52:00.000+00:00",
        createdAt: "2020-11-02T04:48:12.868+00:00",
        updatedAt: "2020-11-02T04:54:12.868+00:00",
        issuedBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        createdBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        updatedBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        notes: "",
    },
    {
        _id: "e8d98a1b-fa0e-4a35-a2f0-df2e343c5ae9",
        quoteNo: "QUO-E8D98A1B",
        client: "2e214095-34df-4c53-bacb-57f159ceec6d",
        items: [
            {
                name: "Weekly smart home event hosting",
                quantity: "4",
                unitPrice: "32500",
            },
            {
                name: "Social media advertisement package",
                quantity: "1",
                unitPrice: "35000",
            },
            {
                name: "Daily customer survey",
                quantity: "30",
                unitPrice: "2500",
            },
        ],
        currency: "HKD",
        serviceFee: "18000.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "28000.00",
        totalAmount: "230000.00",
        paymentTerms: "30% upfront, remaining amount due upon campaign completion, NET 15",
        issuedAt: "2020-12-02T04:23:00.000+00:00",
        createdAt: "2020-12-02T04:28:12.868+00:00",
        updatedAt: "2020-12-02T04:36:12.868+00:00",
        issuedBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        createdBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        updatedBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        notes: "",
    },
    {
        _id: "1d699e35-02e6-476f-aad9-bd0a6cc36e16",
        quoteNo: "QUO-1D699E35",
        client: "dca1a4e0-7828-4782-9b7c-d6030a775a68",
        items: [
            {
                name: "Online streaming promotion for yoga compression apparel",
                quantity: "10",
                unitPrice: "3500",
            },
            {
                name: "Yoga training promotion section for Alphaskin in various districts",
                quantity: "6",
                unitPrice: "32000",
            },
            {
                name: "Social media advertisement package",
                quantity: "1",
                unitPrice: "28000",
            },
        ],
        currency: "HKD",
        serviceFee: "38000.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "45000.00",
        totalAmount: "248000.00",
        paymentTerms: "30% upfront, remaining amount due upon campaign completion, NET 15",
        issuedAt: "2020-11-28T05:33:00.000+00:00",
        createdAt: "2020-11-28T05:29:12.868+00:00",
        updatedAt: "2020-11-28T05:35:12.868+00:00",
        issuedBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        createdBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        updatedBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        notes: "",
    },
    {
        _id: "7606ddc3-9099-425c-b83f-e34b9a7fb1bd",
        quoteNo: "QUO-7606DDC3",
        client: "9383fc7e-7684-443f-bde9-36476db86efe",
        items: [
            {
                name: "Shopping mall promotion event",
                quantity: "1",
                unitPrice: "145000",
            },
            {
                name: "Social media advertisement package",
                quantity: "1",
                unitPrice: "30000",
            },
        ],
        currency: "HKD",
        serviceFee: "5000.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "10000.00",
        totalAmount: "170000.00",
        paymentTerms: "30% upfront, remaining amount due upon campaign completion, NET 15",
        issuedAt: "2020-12-08T04:16:00.000+00:00",
        createdAt: "2020-12-08T04:10:12.868+00:00",
        updatedAt: "2020-12-08T04:20:12.868+00:00",
        issuedBy: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        createdBy: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        updatedBy: "f6d10144-0aa8-4ddc-ad2f-13a2e9ae3ca7",
        notes: "",
    },
    {
        _id: "353a82cf-c74c-42e8-a9c6-fbb0a9e0f8dc",
        quoteNo: "QUO-353A82CF",
        client: "45b6a3c1-c8f9-423f-95d8-133574f5538b",
        items: [
            {
                name: "TV advertisement package",
                quantity: "1",
                unitPrice: "85000",
            },
            {
                name: "Social media advertisement package",
                quantity: "1",
                unitPrice: "40000",
            },
            {
                name: "Email marketing",
                quantity: "1",
                unitPrice: "15000",
            },
        ],
        currency: "HKD",
        serviceFee: "15000.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "12500.00",
        totalAmount: "142500.00",
        paymentTerms: "30% upfront, remaining amount due upon campaign completion, NET 15",
        issuedAt: "2020-12-16T04:57:00.000+00:00",
        createdAt: "2020-12-16T04:56:12.868+00:00",
        updatedAt: "2020-12-16T04:59:12.868+00:00",
        issuedBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        createdBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        updatedBy: "5a32f5f0-dca6-46e6-8ddd-99adb299b9e0",
        notes: "",
    },
    {
        _id: "7a0607b2-f330-4246-ae2f-8859c743977d",
        quoteNo: "QUO-7A0607B2",
        client: "d0ed9856-1f26-4b34-be6a-4bf52f2603a6",
        items: [
            {
                name: "TV advertisement package",
                quantity: "1",
                unitPrice: "70000",
            },
            {
                name: "Social media celebrity promotion",
                quantity: "1",
                unitPrice: "88000",
            },
        ],
        currency: "HKD",
        serviceFee: "10000.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "15000.00",
        totalAmount: "153000.00",
        paymentTerms: "30% upfront, remaining amount due upon campaign completion, NET 15",
        issuedAt: "2020-12-06T07:30:00.000+00:00",
        createdAt: "2020-12-06T07:28:12.868+00:00",
        updatedAt: "2020-12-06T07:31:12.868+00:00",
        issuedBy: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        createdBy: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        updatedBy: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        notes: "",
    },
    {
        _id: "2f53cc82-09ac-411f-90fe-99dff9bb48c9",
        quoteNo: "QUO-2F53CC82",
        client: "23b1562a-8da7-4e7e-a921-bf1c940b67b9",
        items: [
            {
                name: "TV advertisement package",
                quantity: "1",
                unitPrice: "85000",
            },
            {
                name: "Social media advertisement package",
                quantity: "1",
                unitPrice: "35000",
            },
        ],
        currency: "HKD",
        serviceFee: "10000.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "8500.00",
        totalAmount: "121500.00",
        paymentTerms: "30% upfront, remaining amount due upon campaign completion, NET 15",
        issuedAt: "2020-12-16T04:14:00.000+00:00",
        createdAt: "2020-12-16T04:12:12.868+00:00",
        updatedAt: "2020-12-16T04:20:12.868+00:00",
        issuedBy: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        createdBy: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        updatedBy: "b1a34485-9eef-476a-9f24-b02e357adcd3",
        notes: "",
    },
    {
        _id: "cd48702f-ffb5-49ef-a853-66456b3b595c",
        quoteNo: "QUO-CD48702F",
        client: "6d4dd062-665a-4069-acf3-ad9c584913ac",
        items: [
            {
                name: "Shopping mall promotion event in prime locations",
                quantity: "5",
                unitPrice: "125000",
            },
            {
                name: "Social media advertisement package",
                quantity: "1",
                unitPrice: "85000",
            },
        ],
        currency: "HKD",
        serviceFee: "58000.00",
        shippingFee: "0.00",
        tax: "0.00",
        otherCharges: "0.00",
        discount: "68000.00",
        totalAmount: "700000.00",
        paymentTerms: "15% upfront, remaining amount due upon campaign completion, NET 15",
        issuedAt: "2020-09-14T04:45:00.000+00:00",
        createdAt: "2020-09-14T04:42:12.868+00:00",
        updatedAt: "2020-09-14T04:48:12.868+00:00",
        issuedBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        createdBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        updatedBy: "33aa85b7-1170-4161-9f70-46f01bb592c2",
        notes: "",
    },
]