export const deliveryRecords = [
    {
        _id: "92906503-7d4e-492d-9132-1e8e2ff8afa8",
        recordNo: "REC-92906503",
        supplier: "6e85889f-cdf9-4a92-9da5-770dbab2861e",
        purchaseOrder: "9a745e0d-81a7-4b87-ac74-3da9351c272b",
        itemsReceived: [
            {
                name: "Medium promotion booth 10' x 6' daily rental",
                quantity: "14",
                unitPrice: "1500.00",
            },
            {
                name: "Promotion banners",
                quantity: "4",
                unitPrice: "350.00",
            },
        ],
        itemsAccepted: [
            {
                name: "Medium promotion booth 10' x 6' daily rental",
                quantity: "14",
                unitPrice: "1500.00",
            },
            {
                name: "Promotion banners",
                quantity: "3",
                unitPrice: "350.00",
            },
        ],
        itemsRejected: [
            {
                name: "Promotion banners",
                quantity: "1",
                unitPrice: "350.00",
            },
        ],
        receivedTotal: "22400.00",
        receivedBy: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        acceptedBy: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        rejectedBy: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        createdBy: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        updatedBy: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        receivedAt: "2020-12-21T01:12:00.000+00:00",
        acceptedAt: "2020-12-21T01:12:00.000+00:00",
        rejectedAt: "2020-12-21T01:12:00.000+00:00",
        createdAt: "2020-12-21T01:38:16.000+00:00",
        updatedAt: "2020-12-21T01:38:16.000+00:00",
        notes: "",
    },
    {
        _id: "1255bea9-953f-4e07-8eb4-ce9c99f68a71",
        recordNo: "REC-1255BEA9",
        supplier: "40d7f4c8-e39f-4ab9-ae09-97c878e5d2d6",
        purchaseOrder: "51b30aeb-6153-4ff6-8c7f-98c7ab4e3640",
        itemsReceived: [
            {
                name: "Commercial media advertisement",
                quantity: "1",
                unitPrice: "62500.00",
            },
        ],
        itemsAccepted: [
            {
                name: "Commercial media advertisement",
                quantity: "1",
                unitPrice: "62500.00",
            },
        ],
        itemsRejected: [],
        receivedTotal: "62500.00",
        receivedBy: "4ed130ed-858c-4c65-8d7f-b08cbb964f77",
        acceptedBy: "4ed130ed-858c-4c65-8d7f-b08cbb964f77",
        rejectedBy: "",
        createdBy: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        updatedBy: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        receivedAt: "2020-12-09T02:32:00.000+00:00",
        acceptedAt: "2020-12-09T02:32:00.000+00:00",
        rejectedAt: "",
        createdAt: "2020-12-09T03:12:54.000+00:00",
        updatedAt: "2020-12-09T03:12:54.000+00:00",
        notes: "",
    },
    {
        _id: "55155e05-49bd-473a-b3a7-f6c9849d8e68",
        recordNo: "REC-55155E05",
        supplier: "6e85889f-cdf9-4a92-9da5-770dbab2861e",
        purchaseOrder: "9400884f-3c6a-4701-abac-295fc7bb642c",
        itemsReceived: [
            {
                name: "Small promotion booth 6' x 4'",
                quantity: "1",
                unitPrice: "7600.00",
            },
        ],
        itemsAccepted: [
            {
                name: "Small promotion booth 6' x 4'",
                quantity: "1",
                unitPrice: "7600.00",
            },
        ],
        itemsRejected: [],
        receivedTotal: "7600.00",
        receivedBy: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        acceptedBy: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        rejectedBy: "",
        createdBy: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        updatedBy: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        receivedAt: "2021-01-02T01:08:00.000+00:00",
        acceptedAt: "2021-01-02T01:08:00.000+00:00",
        rejectedAt: "",
        createdAt: "2021-01-02T01:38:16.000+00:00",
        updatedAt: "2021-01-02T01:38:16.000+00:00",
        notes: "",
    },
    {
        _id: "23026409-a327-4dfb-80f2-af06e32cacbd",
        recordNo: "REC-23026409",
        supplier: "224e190c-28ce-4f87-a79c-e977ef035f1f",
        purchaseOrder: "a6f49d1c-a80c-4e7b-9332-076ace428ec5",
        itemsReceived: [
            {
                name: "Event platform and staging daily rental",
                quantity: "1",
                unitPrice: "45000.00",
            },
            {
                name: "Event furniture chairs daily rental",
                quantity: "150",
                unitPrice: "50.00",
            },
            {
                name: "Event furniture bench daily rental",
                quantity: "4",
                unitPrice: "150.00",
            },
            {
                name: "Misc event items daily rental",
                quantity: "1",
                unitPrice: "1000.00",
            },
        ],
        itemsAccepted: [
            {
                name: "Event platform and staging daily rental",
                quantity: "1",
                unitPrice: "45000.00",
            },
            {
                name: "Event furniture chairs daily rental",
                quantity: "145",
                unitPrice: "50.00",
            },
            {
                name: "Event furniture bench daily rental",
                quantity: "4",
                unitPrice: "150.00",
            },
            {
                name: "Misc event items daily rental",
                quantity: "1",
                unitPrice: "1000.00",
            },
        ],
        itemsRejected: [
            {
                name: "Event furniture chairs daily rental",
                quantity: "5",
                unitPrice: "50.00",
            },
        ],
        receivedTotal: "54100.00",
        receivedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        acceptedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        rejectedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        createdBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        updatedBy: "dbdc9689-1c25-4dd4-8f50-0fc7fb85d39b",
        receivedAt: "2020-12-23T00:45:00.000+00:00",
        acceptedAt: "2020-12-23T00:45:00.000+00:00",
        rejectedAt: "2020-12-23T00:45:00.000+00:00",
        createdAt: "2020-12-23T01:49:16.000+00:00",
        updatedAt: "2020-12-23T01:49:16.000+00:00",
        notes: "",
    },
    {
        _id: "aec45447-797a-4d33-a8f3-d8ecc40ac517",
        recordNo: "REC-AEC45447",
        supplier: "795990b8-5011-4e24-aec9-57759e363cde",
        purchaseOrder: "c5d6e4c5-c3e6-405e-993e-aa57dbab72c9",
        itemsReceived: [
            {
                name: "Bouquet flower",
                quantity: "6",
                unitPrice: "900.00",
            },
        ],
        itemsAccepted: [
            {
                name: "Bouquet flower",
                quantity: "6",
                unitPrice: "900.00",
            },
        ],
        itemsRejected: [],
        receivedTotal: "5400.00",
        receivedBy: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        acceptedBy: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        rejectedBy: "",
        createdBy: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        updatedBy: "279fcea9-927f-48a2-86ca-bef66bc61fba",
        receivedAt: "2020-12-23T00:38:00.000+00:00",
        acceptedAt: "2020-12-23T00:38:00.000+00:00",
        rejectedAt: "",
        createdAt: "2020-12-23T01:06:13.000+00:00",
        updatedAt: "2020-12-23T01:06:13.000+00:00",
        notes: "",
    },
]